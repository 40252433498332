import React from "react";
import logo from "../img/logo.png";
import { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { NavLink } from "react-router-dom";

function Navbar() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  return (
    <navbar class="flex justify-between lg:justify-evenly flex-col p-2 md:flex-row md:space-x-5 items-center border-b-2 border-slate-400 space-y-5">
      <img src={logo} class="w-[180px] md:1/4" alt="özfilo nakliyat logo" />

      <button
        onClick={() => setOpen(true)}
        className="w-full bg-slate-100 rounded-xl lg:hidden"
      >
        <svg
          className="mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          height="48px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#000000"
        >
          <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
        </svg>
      </button>
      <BottomSheet open={open}>
        <div className="flex flex-col">
          <div className="flex justify-between px-3">
            <NavLink
              to={"/"}
              title="özfilo - anasayfa"
              onClick={() => setOpen(false)}
            >
              Ana Sayfa
            </NavLink>
            <span
              onClick={() => setOpen(false)}
              class="material-symbols-outlined"
            >
              close
            </span>
          </div>
          <div className="flex items-center justify-start px-3">
            <NavLink
              onClick={() => setOpen(false)}
              to={"/hakkimizda"}
              title="özfilo- hakkimizda"
            >
              Hakkımızda
            </NavLink>
            <button onClick={() => setOpen2(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="48px"
                fill="#000000"
              >
                <path d="M480-360 280-560h400L480-360Z" />
              </svg>
            </button>
          </div>
          <NavLink
            className="px-3"
            to={"/hizmetlerimiz"}
            onClick={() => setOpen(false)}
          >
            Hizmetlerimiz
          </NavLink>
          <BottomSheet open={open2}>
            <div className="flex flex-col">
              <div className="flex justify-between px-3">
                <NavLink
                  to={"/evden-eve-tasimacilik"}
                  title="evden-eve-taşimacilik"
                  onClick={() => setOpen(false)}
                >
                  Evden Eve Taşımacılık
                </NavLink>
                <span
                  onClick={() => setOpen2(false)}
                  class="material-symbols-outlined"
                >
                  close
                </span>
              </div>
              <NavLink
                to={"/sehirler-arasi-nakliyat"}
                title="sehirler-arasi-nakliyat"
                className="text-start px-3"
                onClick={() => setOpen(false)}
              >
                Şehirler Arası Nakliyat
              </NavLink>
              <NavLink
                to={"/sehir-ici-nakliyat"}
                className="px-3"
                title="sehir-ici-nakliyat"
                onClick={() => setOpen(false)}
              >
                Şehir İçi Nakliyat
              </NavLink>
              <NavLink
                to={"/ofis-tasima"}
                title="ofis-tasima"
                className="px-3"
                onClick={() => setOpen(false)}
              >
                Ofis Taşıma
              </NavLink>
              <NavLink
                to={"/asansorlu-esya-tasima"}
                title="asansörlü-eşya-taşıma"
                className="px-3"
                onClick={() => setOpen(false)}
              >
                Asansörlü Eşya Taşıma
              </NavLink>
              <NavLink
                to={"/parca-esya-tasima"}
                title="parça-eşya-taşıma"
                className="px-3"
                onClick={() => setOpen(false)}
              >
                Parça Eşya Taşıma
              </NavLink>
              <NavLink
                to={"/esya-depolama"}
                className="px-3"
                title="esya-depolama"
                onClick={() => setOpen(false)}
              >
                Eşya Depolama
              </NavLink>
              <NavLink
                to={"/komple-bina-tasima"}
                title="komple-bina-taşıma"
                className="px-3"
                onClick={() => setOpen(false)}
              >
                Komple Bina Taşıma
              </NavLink>
            </div>
          </BottomSheet>
          <NavLink
            to={"/blog"}
            className="text-start px-3"
            onClick={() => setOpen(false)}
          >
            Blog
          </NavLink>
          <NavLink
            to={"/iletisim"}
            className="text-start px-3"
            onClick={() => setOpen(false)}
          >
            İletişim
          </NavLink>
        </div>
      </BottomSheet>
      <div className="hidden lg:flex w-full justify-center items-center">
        <NavLink
          to={"/"}
          title="özfilo"
          className="text-start px-3 hover:border-b-2 border-black"
        >
          AnaSayfa
        </NavLink>
        <NavLink
          to={"/hizmetlerimiz"}
          title="özfilo-nakliyat"
          className="text-start px-3 group hover:border-b-2 border-black flex items-center cursor-pointer"
        >
          Hizmetlerimiz
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30px"
              viewBox="0 -960 960 960"
              width="30px"
              fill="#000000"
            >
              <path d="M480-360 280-560h400L480-360Z" />
            </svg>
            <div className="hidden group-hover:flex flex-col bg-white  absolute z-10">
              <NavLink
                title="evden-eve-tasimacilik"
                to={"/evden-eve-tasimacilik"}
                className="hover:bg-slate-100 p-3 font-thin text-sm w-full"
              >
                Evden Eve Taşımacılık
              </NavLink>
              <NavLink
                title="şehirler-arasi-nakliyat"
                to={"/sehirler-arasi-nakliyat"}
                className="hover:bg-slate-100 p-3 font-thin text-sm w-full"
              >
                Şehirler Arası Nakliyat
              </NavLink>
              <NavLink
                title="şehir-içi-nakliyat"
                to={"/sehir-ici-nakliyat"}
                className="hover:bg-slate-100 p-3 font-thin text-sm w-full"
              >
                Şehir İçi Nakliyat
              </NavLink>
              <NavLink
                title="ofis-taşıma"
                to={"/ofis-tasima"}
                className="hover:bg-slate-100 p-3 font-thin text-sm w-full"
              >
                Ofis Taşıma
              </NavLink>
              <NavLink
                to={"/asansorlu-esya-tasima"}
                title="asansörlü-eşya-taşıma"
                className="hover:bg-slate-100 p-3 font-thin text-sm w-full"
              >
                Asansörlü Eşya Taşıma
              </NavLink>
              <NavLink
                to={"/parca-esya-tasima"}
                className="hover:bg-slate-100 p-3 font-thin text-sm w-full"
              >
                Parça Eşya Taşıma
              </NavLink>
              <NavLink
                to={"/esya-depolama"}
                title="eşya-depolama"
                className="hover:bg-slate-100 p-3 font-thin text-sm w-full"
              >
                Eşya Depolama
              </NavLink>
              <NavLink
                to={"/komple-bina-tasima"}
                title="komple-bina-taşıma"
                className="hover:bg-slate-100 p-3 font-thin text-sm w-full"
              >
                Komple Bina Nakliyatı
              </NavLink>
            </div>
          </div>
        </NavLink>
        <NavLink
          to={"/hakkimizda"}
          title="özfilo-hakkımızda"
          className="text-start px-3 hover:border-b-2 border-black"
        >
          Hakkımızda
        </NavLink>
        <NavLink
          to={"/blog"}
          title="özfilo-blog"
          className="text-start px-3 hover:border-b-2 border-black"
        >
          Blog
        </NavLink>
        <NavLink
          to={"/iletisim"}
          title="özfilo-iletişim"
          className="text-start px-3 hover:border-b-2 border-black"
        >
          İletişim
        </NavLink>
      </div>

      <div class="flex flex-col md:w-2/5 lg:w-1/4 ">
        <NavLink
          title="özfilo - telefon numarası"
          to={"tel:053220362200016"}
          class="flex items-center"
        >
          <svg
            height="15"
            width="15"
            aria-hidden="true"
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-16.39 307.37l-15 65A15 15 0 0 1 354 416C194 416 64 286.29 64 126a15.7 15.7 0 0 1 11.63-14.61l65-15A18.23 18.23 0 0 1 144 96a16.27 16.27 0 0 1 13.79 9.09l30 70A17.9 17.9 0 0 1 189 181a17 17 0 0 1-5.5 11.61l-37.89 31a231.91 231.91 0 0 0 110.78 110.78l31-37.89A17 17 0 0 1 299 291a17.85 17.85 0 0 1 5.91 1.21l70 30A16.25 16.25 0 0 1 384 336a17.41 17.41 0 0 1-.39 3.37z"></path>
          </svg>
          0532 362 00 16
        </NavLink>
        <NavLink
          title="özfilo - whatsapp"
          to={"whatsapp://send?phone=05323508851"}
          class="flex items-center"
        >
          <svg
            height="15"
            width="15"
            aria-hidden="true"
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M224 122.8c-72.7 0-131.8 59.1-131.9 131.8 0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6 49.9-13.1 4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8 0-35.2-15.2-68.3-40.1-93.2-25-25-58-38.7-93.2-38.7zm77.5 188.4c-3.3 9.3-19.1 17.7-26.7 18.8-12.6 1.9-22.4.9-47.5-9.9-39.7-17.2-65.7-57.2-67.7-59.8-2-2.6-16.2-21.5-16.2-41s10.2-29.1 13.9-33.1c3.6-4 7.9-5 10.6-5 2.6 0 5.3 0 7.6.1 2.4.1 5.7-.9 8.9 6.8 3.3 7.9 11.2 27.4 12.2 29.4s1.7 4.3.3 6.9c-7.6 15.2-15.7 14.6-11.6 21.6 15.3 26.3 30.6 35.4 53.9 47.1 4 2 6.3 1.7 8.6-1 2.3-2.6 9.9-11.6 12.5-15.5 2.6-4 5.3-3.3 8.9-2 3.6 1.3 23.1 10.9 27.1 12.9s6.6 3 7.6 4.6c.9 1.9.9 9.9-2.4 19.1zM400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM223.9 413.2c-26.6 0-52.7-6.7-75.8-19.3L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5 29.9 30 47.9 69.8 47.9 112.2 0 87.4-72.7 158.5-160.1 158.5z"></path>
          </svg>
          0532 350 88 51
        </NavLink>
      </div>
    </navbar>
  );
}

export default Navbar;
