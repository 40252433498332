import React from "react";
import { NavLink } from "react-router-dom";
function Hizmetlerimiz() {
  return (
    <section className="flex flex-col mt-10 lg:gap-10 items-center space-y-10 md:space-y-0 text-center ">
      <h3 className=" text-5xl">Hizmetlerimiz</h3>
      <p>
        <strong>ÖzFilo Evden Eve Nakliyat</strong> olarak Türkiye genelinde{" "}
        <em>
          <strong>
            evden eve nakliyat, eşya taşıma, parça eşya taşıma, şehirler arası
            nakliyat, şehir içi nakliyat, ofis taşıma, işyeri taşıma, asansörlü
            eşya taşıma, asansörlü nakliyat ve eşya depolama
          </strong>
        </em>{" "}
        hizmetleri sunmaktayız. Nakliye hizmetlerimizde tecrübeli ve özverili
        ekip arkadaşlarımızla güvenli ve <strong>sigortalı taşımacılık</strong>{" "}
        hizmetleri sunmaktayız.
      </p>
      <div className="flex flex-col space-y-10 md:space-y-0 md:flex-row  lg:gap-0 lg:w-3/4  md:justify-evenly md:gap-x-1 md:gap-y-5 md:flex-wrap">
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              width="60px"
              height="200px"
              className="fill-white mx-auto"
            >
              <path d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z" />
            </svg>
            <h3 className="text-2xl">Evden Eve Nakliyat</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Evden Eve Nakliyat</h3>
            <p className="text-center">
              Türkiye Genelinde <strong> Evden Eve Nakliyat</strong>{" "}
              Hizmetlerini En Uygun Fiyatlarla ve Müşteri Memnuniyeti Odaklı
              Sunmaktayız.
            </p>
            <NavLink
              to={"/evden-eve-tasimacilik"}
              title="evden-eve-taşımacılık"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              width="60px"
              height="200px"
              className="fill-white mx-auto"
              viewBox="0 0 640 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M621.3 237.3l-58.5-58.5c-12-12-28.3-18.7-45.3-18.7H480V64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v336c0 44.2 35.8 80 80 80 26.3 0 49.4-12.9 64-32.4 14.6 19.6 37.7 32.4 64 32.4 44.2 0 80-35.8 80-80 0-5.5-.6-10.8-1.6-16h163.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16H624c8.8 0 16-7.2 16-16v-85.5c0-17-6.7-33.2-18.7-45.2zM80 432c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm128 0c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm272-224h37.5c4.3 0 8.3 1.7 11.3 4.7l43.3 43.3H480v-48zm48 224c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32z"></path>
            </svg>
            <h3 className="text-2xl">Şehirler Arası Nakliyat</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Şehirler Arası Nakliyat</h3>
            <p className="text-center">
              <strong> Şehirler Arası Nakliyat</strong> Firması Olarak Zamanlama
              Ve Hassasiyet Konularına Önemseyerek Hizmet Vermekteyiz.
            </p>
            <NavLink
              to={"/sehirler-arasi-nakliyat"}
              title="şehirler-arası-nakliyat"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              width="60px"
              height="200px"
              className="fill-white mx-auto"
              viewBox="0 0 640 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M50.2 375.6c2.3 8.5 11.1 13.6 19.6 11.3l216.4-58c8.5-2.3 13.6-11.1 11.3-19.6l-49.7-185.5c-2.3-8.5-11.1-13.6-19.6-11.3L151 133.3l24.8 92.7-61.8 16.5-24.8-92.7-77.3 20.7C3.4 172.8-1.7 181.6.6 190.1l49.6 185.5zM384 0c-17.7 0-32 14.3-32 32v323.6L5.9 450c-4.3 1.2-6.8 5.6-5.6 9.8l12.6 46.3c1.2 4.3 5.6 6.8 9.8 5.6l393.7-107.4C418.8 464.1 467.6 512 528 512c61.9 0 112-50.1 112-112V0H384zm144 448c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"></path>
            </svg>
            <h3 className="text-2xl">Ofis & İşyeri Taşıma</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Ofis & İşyeri Taşıma</h3>
            <p className="text-center">
              <strong> Ofis Taşıma</strong> Ve Her Türlü{" "}
              <strong> İş Yeri Taşıma</strong> Hizmetlerini Profesyonel Ve
              Tecrübeli Ekibimizle Gerçekleştirmekteyiz.{" "}
            </p>
            <NavLink
              to={"/ofis-tasima"}
              title="ofis-taşıma"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              width="60px"
              height="200px"
              className="fill-white mx-auto"
              viewBox="0 0 640 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M616 192H480V24c0-13.26-10.74-24-24-24H312c-13.26 0-24 10.74-24 24v72h-64V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v80h-64V16c0-8.84-7.16-16-16-16H80c-8.84 0-16 7.16-16 16v80H24c-13.26 0-24 10.74-24 24v360c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V216c0-13.26-10.75-24-24-24zM128 404c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm128 192c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm160 96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12V76c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm160 288c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40z"></path>
            </svg>
            <h3 className="text-2xl">Şehir İçi Nakliyat</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Şehir İçi Nakliyat</h3>
            <p className="text-center">
              <strong>Şehir İçi Nakliyat</strong> Hizmetimiz İstanbul İlini
              Kapsamaktadır. En Hızlı Ve Güvenli Şekilde En Uygun Fiyatlarla
              Hizmetinizdeyiz.
            </p>
            <NavLink
              to={"/sehir-ici-nakliyat"}
              title="şehir-içi-nakliyat"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              width="60px"
              height="200px"
              className="fill-white mx-auto"
              viewBox="0 0 640 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M608 320h-64v64h22.4c5.3 0 9.6 3.6 9.6 8v16c0 4.4-4.3 8-9.6 8H73.6c-5.3 0-9.6-3.6-9.6-8v-16c0-4.4 4.3-8 9.6-8H96v-64H32c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32h576c17.7 0 32-14.3 32-32v-96c0-17.7-14.3-32-32-32zm-96 64V64.3c0-17.9-14.5-32.3-32.3-32.3H160.4C142.5 32 128 46.5 128 64.3V384h384zM211.2 202l25.5-25.3c4.2-4.2 11-4.2 15.2.1l41.3 41.6 95.2-94.4c4.2-4.2 11-4.2 15.2.1l25.3 25.5c4.2 4.2 4.2 11-.1 15.2L300.5 292c-4.2 4.2-11 4.2-15.2-.1l-74.1-74.7c-4.3-4.2-4.2-11 0-15.2z"></path>
            </svg>
            <h3 className="text-2xl">Eşya Depolama</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Eşya Depolama</h3>
            <p className="text-center">
              Kısa Veya Uzun Süreli <strong> Eşya Depolama</strong> Hizmeti
              Sunmaktayız. Eşyalarınız Güvenli Depolarımızda Saklanmaktadır.{" "}
            </p>
            <NavLink
              to={"/esya-depolama"}
              title="eşya-depolama"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              width="60px"
              height="200px"
              className="fill-white mx-auto"
              viewBox="0 0 576 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M519.442 288.651c-41.519 0-59.5 31.593-82.058 31.593C377.409 320.244 432 144 432 144s-196.288 80-196.288-3.297c0-35.827 36.288-46.25 36.288-85.985C272 19.216 243.885 0 210.539 0c-34.654 0-66.366 18.891-66.366 56.346 0 41.364 31.711 59.277 31.711 81.75C175.885 207.719 0 166.758 0 166.758v333.237s178.635 41.047 178.635-28.662c0-22.473-40-40.107-40-81.471 0-37.456 29.25-56.346 63.577-56.346 33.673 0 61.788 19.216 61.788 54.717 0 39.735-36.288 50.158-36.288 85.985 0 60.803 129.675 25.73 181.23 25.73 0 0-34.725-120.101 25.827-120.101 35.962 0 46.423 36.152 86.308 36.152C556.712 416 576 387.99 576 354.443c0-34.199-18.962-65.792-56.558-65.792z"></path>
            </svg>
            <h3 className="text-2xl">Parça Eşya Taşıma</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Parça Eşya Taşıma</h3>
            <p className="text-center">
              Adet Sınırı Olmadan Tüm Türkiye Geneli{" "}
              <strong> Parça Eşya Taşıma</strong> Hizmetini En Ekonomik Fiyatlar
              Ve En Güvenli Şekilde Taşıyoruz.{" "}
            </p>
            <NavLink
              to={"/parca-esya-tasima"}
              title="parça-eşya-taşıma"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              width="60px"
              height="200px"
              className="fill-white mx-auto"
              viewBox="0 0 320 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M313.553 119.669L209.587 7.666c-9.485-10.214-25.676-10.229-35.174 0L70.438 119.669C56.232 134.969 67.062 160 88.025 160H152v272H68.024a11.996 11.996 0 0 0-8.485 3.515l-56 56C-4.021 499.074 1.333 512 12.024 512H208c13.255 0 24-10.745 24-24V160h63.966c20.878 0 31.851-24.969 17.587-40.331z"></path>
            </svg>
            <h3 className="text-2xl">Evden Eve Nakliyat</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Asansörlü Eşya Taşıma</h3>
            <p className="text-center">
              Adet Gözetmeden <strong>Asansörlü Eşya Taşıma </strong> Hizmeti
              Veriyoruz. Eşyalarınızı Binanın Dışına Asansör Kurarak Dilediğiniz
              Kata Çıkarıyoruz.
            </p>
            <NavLink
              to={"/evden-eve-tasimacilik"}
              title="evden-eve-taşımacılık"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              width="60px"
              height="200px"
              className="fill-white mx-auto"
              viewBox="0 0 384 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 40c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm121.2 231.8l-143 141.8c-4.7 4.7-12.3 4.6-17-.1l-82.6-83.3c-4.7-4.7-4.6-12.3.1-17L99.1 285c4.7-4.7 12.3-4.6 17 .1l46 46.4 106-105.2c4.7-4.7 12.3-4.6 17 .1l28.2 28.4c4.7 4.8 4.6 12.3-.1 17z"></path>
            </svg>
            <h3 className="text-2xl">Sigortalı Nakliyat</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Sigortalı Nakliyat</h3>
            <p className="text-center">
              Taşınma Sürecinde Eşyalarınızı Tüm Olumsuzluklara Karşı
              Sigortalayarak <strong>Sigortalı Nakliyat</strong> Hizmetini
              Sağlıyoruz.
            </p>
            <NavLink
              to={"/sigortalı-nakliyat"}
              title="sigortalı-nakliyat"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
        <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
          <div className="group-hover:blur-md transition-all duration-500">
            <svg
              width="60px"
              height="200px"
              className="fill-white mx-auto"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"></path>
            </svg>
            <h3 className="text-2xl">Komple Bina Nakliyatı</h3>
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
            <h3 className="text-2xl">Komple Bina Nakliyatı</h3>
            <p className="text-center">
              Genellikle Kentsel Dönüşüm Ya Da Farklı Bir Sebeple Binadaki
              Eşyaların Komple Taşınması Gereken Durumlarda
              <strong>Bina Taşıma</strong> Hizmetini Veriyoruz.
            </p>
            <NavLink
              to={"/komple-bina-tasima"}
              title="komple-bina-taşıma"
              className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
            >
              İnceleyin
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hizmetlerimiz;
