import React from "react";
import hakkimizda from "../img/evden-eve.jpeg";
function Hakkimizda() {
  return (
    <div className=" text-xl mt-10 text-gray-600 ">
      <h1 className="text-center text-5xl text-black my-10 ">
        Özfilo Nakliyat <br /> Hakkımızda
      </h1>
      <img
        src={hakkimizda}
        alt="özfilo-nakliyat-hakkimizda"
        title="özfilo-nakliyat-hakkımızda"
      />
      <div className="p-5 mt-5 mb-10 ">
        <p>
          Ülkemiz her geçen gün ihtiyaçlar konusunda değişiyor. Nakliyat
          Firmalarının da bu değişime ayak uydurup kendilerini aynı hızda
          geliştirmeleri gerektiğine inanıyoruz. Bu bilinçle evden eve nakliyat
          firmamızı 1995 yılında İstanbul Anadolu Yakasında kurduk, her gün
          kendimizi ve hizmetlerimizi iyileştirerek yolumuza devam ediyoruz.{" "}
          <br />
          İşimizin en önemli kısmının iyi bir ekip kurmak olduğuna inanıyoruz.
          Dolayısıyla Nakliyat firmaları aramızdaki farkımız tamamen budur.
          Firma kurucumuz dediği gibi ;’ Her başarılı firma , başarılı çalışma
          arkadaşları ile olur’ sözünü kendimize şiar olarak ilke edindik.
        </p>

        <h2 className="text-black text-4xl mt-20 mb-5">Biz Kimiz ?</h2>
        <p>
          İstanbul ÖZFİLO NAKLİYAT olarak yıllardır yolumuza devam eden bir
          firmayız. Kaliteli ve güvenli bir Nakliyat için sürekli ekip ve
          ekipmanlarımızı yenileyerek hizmet kalitemizi arttırıyoruz.
          <br />
          Bu gün geldiğimiz noktada İstanbul’un tüm ilçelerime Evden Eve
          Nakliyat , Asansörlü Nakliyat ,Parça Eşya Taşıma, Eşya Depolama , Ofis
          ve İşyeri Taşıma hizmetlerini verecek kapasiteye ulaşmış durumdayız.
          Evden Eve Nakliyat araç sayımızı 19 adet çeşitli m3 araçlarla tüm
          İstanbul ilçelerine hizmet sunmaktayız. Bu amaç doğrultusunda daima
          kendimizi geliştiriyoruz.
        </p>

        <h2 className="text-black text-4xl mt-20 mb-5">Vizyonumuz</h2>
        <p>
          Amacımız taşınan herkes için evden eve nakliyatı mümkün olduğunca
          kolaylaştırmak ve etkin bir şekilde yöneterek sonuçlandırmaktır.
          <br />
          Biz şirketimizi bu neden ile kurduk ve tek vazgeçilmezimiz iyi hizmet
          politikamızdır. Eğer sizde iyi hizmeti önemsiyorsanız doğru
          yerdesiniz.
        </p>
        <h2 className="text-black text-4xl mt-20 mb-5">Misyonumuz</h2>
        <p>
          Nakliyat ve Evden Eve Nakliyat konusunda profesyonel yapısıyla
          güçlendiren,
          <br />
          Müşterilerimizin memnuniyetini arttırmayı ilke edinen,
          <br />
          Maliyet odaklılık ve sürdürülebilir karlılık anlayışı ile tüm
          müşterilerin fayda sağlayan,
          <br />
          Kaliteli , güvenilir ve sorunsuz bir nakliyat çözüm ortağı olmaktır.
        </p>
      </div>
    </div>
  );
}

export default Hakkimizda;
