import React from "react";
import img1 from "../../img/slider/ofis-tasima.jpg";
import img2 from "../../img/Hizmetler/evden-eve-nakliyat-ofis-tasima-768x432.jpg";
import img3 from "../../img/Hizmetler/ofis-tasimaciligi-768x512.jpg";
import img4 from "../../img/Blog/sultanbeyli-sehir-ici-nakliyat-768x480.jpg";
import img5 from "../../img/Hizmetler/isyeri-tasima-768x576.jpg";
import { NavLink } from "react-router-dom";
function Ofistas() {
  return (
    <div>
      <h1 className="text-5xl text-center mt-10">ÖzFilo - Ofis Taşıma</h1>
      <img src={img1} className="mx-auto my-10" alt="Özfilo - Ofis Taşıma" />
      <div className="p-5">
        <div className=" flex flex-col space-y-10 my-10 text-gray-600">
          <p>
            <strong>Özfilo Evden Eve Nakliyat</strong> olarak{" "}
            <strong>İstanbul</strong> tüm ilçe ve semtlerinde{" "}
            <em>
              <strong>işyeri taşıma ve ofis taşıma</strong>
            </em>{" "}
            konusunda çok tecrübelidir. Bu konudaki referanslarımız da bizlere
            ayrı bir özgüven katmaktadır.{" "}
            <strong>İşyeri Eşyası taşıma ve Ofis Eşyası Taşıma</strong> yaklaşık
            20 yılı aşkındır İstanbul da hizmet verdiğimiz alanlardan biridir.
            Kaliteli , güvenli ve düzenli bir Ofis ve İşyeri taşıma hizmeti
            almak isterseniz bizleri web sayfalarımız ve iletişim numaralarımızı
            arayarak ulaşabilirsiniz.{" "}
            <em>İşyeri eşya nakliyatı ve Ofis eşya nakliyatı</em> dendiğinde
            İstanbul da akla gelen firmalardan biriyiz. Kaliteli, güvenli ve
            güvenilir hizmet almak için lütfen bize ulaşın.
          </p>
          <p>
            Firmamız, nakliye alanında hizmet sunan ve sunduğu kaliteli hizmet
            ile <strong>ofis taşıma</strong> işlemlerini sorunsuz olacak şekilde
            gerçekleştiren başarılı bir firmadır. Nakliyat alanında uzun süredir
            hizmet vermekte olan <strong>Özfilo evden eve nakliyat </strong>
            firmamız, tecrübeli ekibi ve son teknoloji ekipmanları ile nakliye
            işlemlerinizi, kısa zaman içerisinde ve güvenli olacak şekilde
            gerçekleştirilmesini sağlamaktadır.
          </p>
          <p>
            <strong>Ofis taşıma </strong>konusundaki başarımızı, yıllardır
            verdiğimiz hizmetler sonucunda edindiğimiz bilgi birikim ve
            tecrübelere borçluyuz. Ofisinizde bulunmakta olan eşyalarınız, kısa
            zaman içerisinde ve güvenli bir şekilde belirttiğiniz yeni
            adresinize firmamız aracılığı ile taşıma işlemi
            gerçekleştirilmektedir. Müşteri memnuniyetine önem veren firmamız,
            siz değerli müşterilerimizin eşyalarına sigorta uygulayarak
            eşyalarınızı güvence altına alarak korumaktadır.
          </p>
        </div>

        <div>
          <h2 className="mx-auto text-3xl mt-10 mb-3">
            Evden Eve Nakliyat ile Ofis ve İşyeri Taşıma
          </h2>
          <img
            src={img2}
            className="mx-auto"
            alt="Özfilo - Evden Eve Nakliyat Firması"
          />
          <div className=" flex flex-col space-y-10 my-10 text-gray-600">
            <p>
              <strong>Ofis taşıma </strong>işlemi son derece önem verilmesi
              gereken hassas bir konudur. Taşıma şirketi seçimi yapılırken
              kaliteli firmalar tercih edilmelidir. Nakliye firmasının sigorta
              uygulayan güvenilir firmalar arasında yer aldığına mutlaka dikkat
              edilmelidir. Eşyalarda meydana gelebilecek hasar ihtimali göz
              önüne alınmalı ve zararın karşılanabilmesi için eşyalarınıza
              sigorta sisteminin uygulandığından emin olmanız gerekmektedir.
            </p>
            <p>
              <strong>İşyeri taşıma </strong>işleminizi gerçekleştirebilmek için
              öncelikle expertiz raporu hazırlanması gerekmektedir. Hazırlanan
              rapor sonucunda taşıma işlemlerinde kullanılacak araç, gereç,
              nakliye sırasında kullanılacak aracın büyüklüğü, nakliye ücreti ve{" "}
              <strong>ofis eşyası taşıma hizmeti </strong>için gün belirlenir.
              Belirlenen gün ve saatte nakliyenin kısa zamanda gerçekleşmesi
              için gereken profesyonel elemanların sayısı belirlenerek hesaba
              dahil edilir.
            </p>
            <p>
              Belirlenen gün ve saatte, alanında tecrübeli ekip arkadaşlarımız,{" "}
              <strong>ofis taşıma</strong> işlemi sırasında kullanılacak
              ekipmanları ile nakliye için hazırlık çalışmalarına başlar.
              Nakliye için ürünlerin ambalajlanması gerektiğinden, ilk etapta
              küçük parça eşyalar ambalajlanarak işe başlanır. Nakliyat
              sırasında en çok dikkat edilmesi gereken, ürünlerin
              ambalajlanmasının doğru ve özenli bir şekilde yapılmasının gerekli
              oluşudur.
            </p>
            <p>
              Ambalajın iyi yapılmadığı durumlarda, nakliye sırasında koli
              alttan açılarak ürünler dökülebilir ve koli içindeki ürünler
              kırılarak zarar görebilir. Firma çalışanlarımız tecrübeli
              kişilerden meydana geldiğinden <strong>ofis taşıma</strong>{" "}
              işlemlerinde kolilerin düzgün bir şekilde paketlenmesi
              sağlandığından herhangi bir sorun meydana gelmemektedir. Parça
              eşyalar kaldırılınca büyük çaplı eşyaların sökme işlemlerinin
              yapılabilmesi için alan sağlanır.
            </p>
            <p>
              <strong>Ofis taşıma</strong> işlemleri sırasında taşınması zor
              olan eşyalar, vidalarından sökülür ve ambalajlanarak nakliye
              araçlarına taşınır. Eşyalar, araç içerisinde ağır olanlar alta
              gelecek şekilde konulur ve özenle yerleştirilir. Hafif olan ofis
              eşyalarının nakliye sırasında zarar görmesini engellemek için
              gerekli tedbirler alınır. Eşyalar taşınacağı yere götürülür,
              montajları yapılarak kullanıma hazır hale getirilir
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ">
          <NavLink
            to={"tel:05323620016"}
            title="özfilo - iletişim"
            className="flex items-center lg:w-2/4 xl:w-1/4 mx-auto my-5 justify-center group hover:text-blue-700 hover:border-blue-700 hover:border-2 gap-3 p-3 border border-gray-600 text-gray-600 text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-gray-600 group-hover:fill-blue-700"
              width="30px"
              height="30px"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
            </svg>
            0532 362 0016
          </NavLink>
          <NavLink
            to={
              "https://wa.me/905323508851?text=Merhaba%20Nakliye%20Hakk%C4%B1nda%20Bilgi%20Almak%20%C4%B0stiyorum"
            }
            title="özfilo - iletişim"
            className="flex my-5 bg-[#25D366] lg:w-2/4 xl:w-1/4 mx-auto items-center justify-center group  hover:border-black hover:border-2 gap-3 p-3 border border-black text-white text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-white"
              width="30px"
              height="30px"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
            </svg>
            0532 350 88 51
          </NavLink>
        </div>
        <div>
          <h2 className="mx-auto text-3xl mt-10 mb-3">Ofis Taşımacılığı</h2>
          <img
            src={img3}
            className="mx-auto"
            alt="Özfilo - Ofis Taşımacılığı"
          />
          <div className=" flex flex-col space-y-10 text-gray-600 my-10">
            <p>
              İstanbul iş alanının en yoğun olduğu bölgelerden bir tanesidir ve
              aktif olarak ofisler farklı alanlara taşınabilmektedir.
              <strong> Ofis taşıma İstanbul</strong> ilinin tüm ilçelerine
              yapılmaktadır ve firmamız, ofisinizin nakliyesini sigorta
              kapsamında gerçekleştirmektedir. Ofis taşımacılığı oldukça dikkat
              gerektiren bir iştir bu nedenle nakliye için anlaşma yaptığınız
              firmanın güvenilir ve deneyimli kişilerden oluşması gerekmektedir.
            </p>
            <p>
              Nakliyenin başarılı ve sorunsuz geçmesini sağlamak için firma
              olarak teknolojiyi yakından takip etmekte ve en son çıkan
              teknolojik ürünlerden istifade etmekteyiz. Ofisiniz oldukça yüksek
              bir katta bulunsa dahi asansörlü taşıma sistemimizi kullanarak
              eşyaları indirebilir ya da çıkarabiliriz. Asansör sisteminin
              sağladığı avantaj nedeniyle, eşyalarınız kısa zaman içerisinde
              nakliye aracımıza taşınması sağlanabilmektedir.
            </p>
            <p>
              Firmamız, <strong>ofis taşıma </strong>işlemlerini başarılı
              şekilde gerçekleştirmekte ve nakliye sektöründe güvenilirliğiyle
              tanınmaktadır. Nakliye işlemlerini gerçekleştiren kadromuzdaki
              elemanlarımız tecrübeli kişilerdir ve işlerini profesyonel şekilde
              tamamlamaktadırlar. Ofisinizin taşınma işlemlerinde bize
              güvenebilir ve taşınma işlemlerinizi firmamız aracılığıyla hızlı
              ve güvenilir bir şekilde tamamlayabilirsiniz. Ofisinizde yer alan
              tüm eşyalarınıza aynı özen ve itina gösterilmektedir.
            </p>
            <p>
              Firmamız kadrosunda yer alan tüm çalışma arkadaşlarımız tecrübe
              sahibi kişilerden meydana gelmektedir. Profesyonel kadromuzla,
              nakliye sırasında herhangi bir sorun yaşamazsınız.{" "}
              <strong>Ofis taşıma </strong>&nbsp;işlemlerinizi en kısa zamanda
              ve en iyi şekilde gerçekleştirmek için gayret ederiz. Eşyalarınız
              sigortalı olarak taşındığından nakliye sırasında oluşabilecek
              herhangi bir hasara karşı firmamızın garantisi kapsamında yer
              almaktadır.
            </p>
            <p>
              Eşyaların paketlenmesi yapılırken ve paketlenmesi tamamlanan
              eşyaların nakliye aracına taşınması sırasında oldukça dikkat
              edilmesi gerekmektedir. <strong>Ofis taşıma </strong>işleminin
              profesyonel kişiler tarafından yapılması son derece önemlidir.
              Ofis eşyaları oldukça hassas ve kırılmaya müsait eşyalardan
              oluştuğundan, nakliye sırasında da son derece özenli ve dikkatli
              hareket edilmesi eşyaların zarar görmemesi açısından önem
              taşımaktadır.
            </p>
          </div>
        </div>
        <div>
          <h2 className="mx-auto text-3xl mt-10 mb-3">
            Şehir İçi Evden Eve Nakliyat
          </h2>
          <img
            src={img4}
            className="mx-auto"
            alt="Özfilo - Şehir dışı ev taşıma firması"
          />
          <div className=" flex flex-col text-gray-600 space-y-10 my-10">
            <p>
              <strong>Şehir içi evden eve nakliyat</strong> hizmetini sunarken
              profesyonel ve kaliteli çalışmalar yürütmekteyiz. Sistematik bir
              şekilde planladığımız taşınma sürecini kusursuz
              gerçekleştirmekteyiz. Bu sayede siz değerli müşterilerimizin
              hesaplaması gereken en küçük bir ayrıntıyla bile
              uğraştırmamaktayız.
            </p>
            <p>
              Sorunsuz ve güvenilir hizmetler sunmakta ve yalnızca sizin
              talepleriniz doğrultusunda istediğiniz şekilde hareket etmekteyiz.
              Gerek paketleme gerekse eşyaları araca yükleme esnasında,
              planımıza sadık fakat sizin yönlendirmelerinizi dikkate alarak
              çalışmaktayız. Eşyalarınızı paketleme işlemi sırasında, piyasadaki
              en kaliteli ambalaj malzemelerini tercih etmekteyiz. Eşyaların
              cinsine göre analizler yaptıktan sonra güvenli bir paketleme
              işlemi yapmaktayız.
            </p>
            <p>
              Paketleme işlemi sonrası <strong>evden eve nakliye</strong>{" "}
              hizmetimizi profesyonel bir şekilde gerçekleştirmekteyiz. Firmamız
              bünyesinde çalışan ekip arkadaşlarımız, alanında eğitim almış ve
              deneyimli personellerden oluşmaktadır. Ayrıca gerek duyduğumuz
              alanlarda kullanmak üzere bulundurduğumuz ekipmanlarımız son
              teknoloji ekipmanlardır. Bu noktada gelişen teknolojiyi yakından
              takip etmekte ve hizmet kalitemizi arttırmak amacıyla son
              teknoloji araçlar tercih etmekteyiz.
            </p>
            <p>
              Maddi manevi içinizin rahat olacağı taşınma işlemlerinizde sigorta
              kapsamından faydalanmanızı öneririz. Büyük bir dikkat ve özveriyle
              çalışan uzman ekibimiz, taşınma işinizi en hızlı şekilde
              gerçekleştirmektedir. Hızlı bir paketleme sürecinin ardından hızlı
              bir sevk süreci ve yine hızlı bir eşya yerleştirme işlemi ile
              vakitten tasarruf yapmanızı sağlamaktayız. Şehir içi taşımacılık
              esnasında konforlu bir deneyim yaşamak için Özfilo Evden Eve
              Nakliyat firmamızı tercih edebilirsiniz.
            </p>
          </div>
        </div>
        <div>
          <h2 className="mx-auto text-3xl mt-10 mb-3">
            Ofis Taşıma Şirketleri
          </h2>

          <div className=" flex flex-col text-gray-600 space-y-10 my-10">
            <p>
              <strong>Özfilo evden eve nakliyat </strong>firması benzer taşıma
              şirketleri arasından tecrübesi, başarıları ve hizmet kalitesi ile
              öne çıkmaktadır. <strong>Ofis taşıma </strong>işlemlerinde
              profesyonel ekip ve ekipmanları ile daima kaliteli hizmet vermekte
              olan firmamız, siz değerli müşterilerimizin taktirini ve
              beğenisini kazanmış durumdadır. Firmamız, nakliye sektöründe ki
              başarısı nedeniyle kaliteli firmalar arasında yer almaktadır.
            </p>
            <p>
              Yıllardır nakliyat alanında başarılı şekilde hizmet sunan
              firmamız, paketleme, taşınması zor eşyaların sökülerek yeniden
              montajlanmasını özen ve itinayla yapmaktadır. Nakliye sırasında
              son derece dikkatli, hızlı ve özenli şekilde hareket edilmekte,
              eşyalarınız sigortalanarak güvencemiz altına alınmaktadır. Müşteri
              memnuniyetine önem veren firmamız,<strong> ofis taşıma</strong> ve
              nakliye işlemlerinde müşterilerimizin istekleri doğrultusunda
              hareket etmektedir.
            </p>
            <p>
              Firmamıza ait nakliye asansörlerimiz bulunmaktadır ve istemeniz
              halinde asansör sistemimizi eşyaların nakliyesi sırasında
              kullanabilir, böylelikle daha hızlı nakliye hizmeti
              sağlayabiliriz. Asansör sistemi kullanılması ile yüksek katlarda
              bulunan ofislerin, <strong>ofis taşıma </strong>işlemleri kısa
              zamanda ve güvenli şekilde sağlanmaktadır. İşimize gösterdiğimiz
              özen ve itina nedeniyle müşterilerimiz tarafından beğenilen
              firmalar arasında yer almaktayız.
            </p>
            <p>
              Asansörlü taşıma yöntemi kullanımı diğer taşıma yöntemleri
              arasında, yüksek katlı binalar için en çok tavsiye edilen nakliye
              yöntemidir. Bu yöntemin kullanılması durumunda, diğer yöntemlere
              kıyasla nakliye işlemi çok daha erken ve daha güvenli olacak
              şekilde gerçekleştirilmesi mümkün olmaktadır.
            </p>
          </div>
        </div>
        <div>
          <h2 className="mx-auto text-3xl mt-10 mb-3">
            Ofis ve İşyeri Eşyası Şehirler Arası Nakliyat
          </h2>

          <div className=" flex flex-col text-gray-600 space-y-10 my-10">
            <p>
              <strong>Ofis eşyası nakliyatı </strong>İstanbul’un tüm ilçelerine
              yapıldığı gibi aynı zamanda ise ülkemizin tüm illerine de nakliyat
              hizmeti firmamız tarafından verilmektedir. Ofis içerisinde bulunan
              eşyalarınız, profesyonel ekibimiz tarafından oldukça dikkatli bir
              şekilde nakliye araçlarımıza özen ve itinayla taşınır. Eşyaların
              muntazam şekilde araç içerisine yüklenmesi sağlandığından,
              nakliyat sırasında zarar görmelerinin önüne geçilmektedir.
            </p>
            <p>
              Ofis eşyaları genelde teknolojik ürünlerden meydana
              gelebildiğinden, araç içindeki konumlandırılması da oldukça önem
              verilmesi ve dikkat edilmesi gereken konular arasında yer alır.
              Uzman ekibimiz, <strong>ofis taşıma </strong>işlemi sırasında
              eşyaların nakliye aracına taşınmasında ve araç içerisine
              yerleştirme sırasında gösterdikleri itina ve özeni, belirtilen
              adrese eşyalar teslim edilirken de göstermeye devam etmektedir.
            </p>
            <p>
              Firma çalışanlarımız, <strong>ofis taşıma </strong>işlemleri
              sırasında oldukça titiz davranmakta, eşyalarınızı dikkatli şekilde
              ambalajlayarak nakliyata hazır hale getirmektedirler. İşlerini
              hızlı ve dikkatli şekilde yapmaya özen gösterdiklerinden, ofis
              eşyalarınız kısa zamanda nakliye için uygun hale getirilecek ve
              kısa zaman içerisinde nakliye gerçekleşecektir. Eşyaların
              nakliyesini gerçekleştirecek olan şoförlerimiz yollara hakim,
              dikkatli ve profesyoneldirler. &nbsp;
            </p>
            <p>
              Nakliye işlemleriniz için firmamıza 7/24 ulaşabilir, randevu kaydı
              oluşturarak hizmetimizden faydalanabilirsiniz. İstemeniz halinde
              bize <strong>ozfilonakliyat.com</strong> web sitesi üzerinden de
              ulaşabilir, siz değerli müşterilerimiz için sunduğumuz hizmetler
              hakkında detaylı bilgi alabilirsiniz. Nakliyat fiyatları eşyanın
              çokluğuna ve mesafeye göre değişiklik gösterdiğinden nakliye
              öncesi yapılacak expertiz raporu sonucuna göre{" "}
              <strong>ofis taşıma </strong>fiyatı belirlenmektedir.
            </p>
          </div>
        </div>
        <div>
          <h2 className="mx-auto text-3xl mt-10 mb-3">
            Şehir İçi Evden Eve Nakliyat
          </h2>
          <img
            src={img5}
            className="mx-auto"
            alt="Özfilo - Şehir dışı ev taşıma firması"
          />
          <div className=" flex flex-col text-gray-600 space-y-10 my-10">
            <p>
              <em>
                İşyeri taşıma, işyeri eşyası taşıma ya da işyeri nakliyatı
              </em>{" "}
              da diyebileceğimiz bu hizmetimizde ister ofis ister işyeri olsun
              her türlü eşyanız güvenle taşınır. Her türlü{" "}
              <strong>işyeri taşıma</strong> hizmetini sunmaktayız. İstanbul
              merkezli firmamız İstanbul başta olmak üzere tüm Türkiye geneline
              işyeri taşıma işlemlerini yürütmektedir. Özfilo evden eve nakliyat
              olarak işyerinizi tam sizin istediğiniz gibi en hızlı ve güvenli
              şekilde uygun fiyatlarla taşıma görevini üstlenmekteyiz. Bilgi ve
              nakliyat işlemleri için hemen bizi arayın.&nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ofistas;
