import React from "react";
import Slider from "react-slick";
import res1 from "../img/slider/evden-eve-nakliyat2.jpg";
import res2 from "../img/slider/ozfilo-evden-eve-nakliyat.jpg";
import res3 from "../img/slider/sigortali-ev-tasima.jpg";
import res4 from "../img/slider/asansorlu-nakliyat.jpg";
import res5 from "../img/slider/yerli-yerine-esya-tasima.jpg";
import { NavLink } from "react-router-dom";
function SliderImg() {
  var settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: false,
    autoplaySpeed: 2000,
  };
  return (
    <Slider {...settings} className="h-auto w-full">
      <div className=" relative">
        <img
          src={res1}
          alt="evden-eve-nakliyat"
          className=" w-full brightness-50 "
        />
        <div className=" w-full absolute z-10 text-white top-0 left-0 h-full flex flex-col justify-evenly p-5 lg:p-10">
          <h2 className=" w-full  text-center md:text-7xl">
            EVDEN EVE NAKLİYAT
          </h2>
          <p className="text-xs text-center  md:text-2xl lg:text-4x">
            Tüm Türkiye Genelinde <strong>Evden Eve Nakliyat</strong> Hizmeti
            Sunuyoruz.26 Yıllık Tecrübemizle Kaliteli Hizmetler Sunuyoruz
          </p>
          <div className=" flex justify-evenly">
            <NavLink
              to={"/hizmetlerimiz"}
              title="özfilo - hizmetlerimiz"
              className=" bg-white text-black px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Bizi Tanıyın
            </NavLink>
            <NavLink
              to={"/iletisim"}
              title="özfilo - iletisim"
              className=" bg-transparent border border-white px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Teklif Al
            </NavLink>
          </div>
        </div>
      </div>
      <div className=" relative">
        <img
          src={res2}
          alt="ozfilo-evden-eve-nakliyat"
          className=" w-full brightness-50 "
        />
        <div className=" w-full absolute z-10 text-white top-0 left-0 h-full flex flex-col justify-evenly p-5 lg:p-28">
          <h2 className=" w-full  text-center md:text-7xl">ÖZFİLO NAKLİYAT</h2>
          <p className="text-xs text-center  md:text-2xl lg:text-4x">
            Firmamız <strong>Ev</strong>,<strong>Ofis</strong>
            <strong>Eşya Taşımacılığı</strong> Hizmeti Vermektedir.Tüm Türkiye
            Geneline <strong>Evden Eve Nakliyat</strong>,
            <strong>Şehirler Arası Nakliyat</strong>,
            <strong>Eşya Depolama</strong>, <strong>Parça Eşya Taşıma</strong>,
            <strong>Asansörlü Nakliyat </strong>
            <strong>ve </strong>
            <strong>Evden Eve Taşıma</strong> Hizmetlerini Özverili Ekibiyle
            Gerçekleştirmektedir.
          </p>
          <div className=" flex justify-evenly">
            <NavLink
              to={"/hizmetlerimiz"}
              title="özfilo - hizmetlerimiz"
              className=" bg-white text-black px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Bizi Tanıyın
            </NavLink>
            <NavLink
              to={"/iletisim"}
              title="özfilo - iletisim"
              className=" bg-transparent border border-white px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Teklif Al
            </NavLink>
          </div>
        </div>
      </div>
      <div className=" relative">
        <img
          src={res3}
          alt="sigortalı-ev-taşıma"
          className=" w-full brightness-50 "
        />
        <div className=" w-full absolute z-10 text-white top-0 left-0 h-full flex flex-col justify-evenly p-5 lg:p-28">
          <h2 className=" w-full  text-center md:text-7xl">SİGORTALI TAŞIMA</h2>
          <p className="text-xs text-center font-thin md:text-2xl lg:text-4x">
            Firmamız Tüm Nakliyat Hizmetlerinde
            <strong>Sigortalı Eşya Taşıması</strong>,
            <strong>Sigortalı Ev Taşıma Hizmeti</strong>Vermektedir,
            <strong>ÖzFilo Nakliyat</strong>'la Güvendesiniz
          </p>
          <div className=" flex justify-evenly">
            <NavLink
              to={"/hizmetlerimiz"}
              title="özfilo - hizmetlerimiz"
              className=" bg-white text-black px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Bizi Tanıyın
            </NavLink>
            <NavLink
              to={"/iletisim"}
              title="özfilo - iletisim"
              className=" bg-transparent border border-white px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Teklif Al
            </NavLink>
          </div>
        </div>
      </div>
      <div className=" relative">
        <img
          src={res4}
          alt="asansörlü-nakliyat-taşıma"
          className=" w-full brightness-50 "
        />
        <div className=" w-full absolute z-10 text-white top-0 left-0 h-full flex flex-col justify-evenly p-5 lg:p-28">
          <h2 className=" w-full  text-center md:text-7xl">
            ASANSÖRLÜ NAKLİYAT
          </h2>
          <p className="text-xs text-center font-thin md:text-2xl lg:text-4x">
            Tüm Nakliye işlerimizde gerekli durumlarda ya da müşteri talebi
            doğrutulsunda postatis asansörümüzle{" "}
            <strong>Asansörlü Nakliyat</strong> hizmeti sunmaktayız
          </p>
          <div className=" flex justify-evenly">
            <NavLink
              to={"/hizmetlerimiz"}
              title="özfilo - hizmetlerimiz"
              className=" bg-white text-black px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Bizi Tanıyın
            </NavLink>
            <NavLink
              to={"/iletisim"}
              title="özfilo - iletisim"
              className=" bg-transparent border border-white px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Teklif Al
            </NavLink>
          </div>
        </div>
      </div>
      <div className=" relative">
        <img
          src={res5}
          alt="evden-eve-nakliyat-ozfilo"
          className=" w-full brightness-50 "
        />
        <div className=" w-full absolute z-10 text-white top-0 left-0 h-full flex flex-col justify-evenly p-5 lg:p-10">
          <h2 className=" w-full  text-center md:text-7xl">
            HER ŞEY TAM YERİNE
          </h2>
          <p className="text-xs text-center font-thin md:text-2xl lg:text-4x">
            Tüm Eşyalarınızı Düngünce Zarar Görmeyecek Şekilde Paketliyor Özenle
            Taşıyor ve Yeni Evinizde Aynı Şekilde Yerleştiriyoruz
          </p>
          <div className=" flex justify-evenly">
            <NavLink
              to={"/hizmetlerimiz"}
              title="özfilo - hizmetlerimiz"
              className=" bg-white text-black px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Bizi Tanıyın
            </NavLink>
            <NavLink
              to={"/iletisim"}
              title="özfilo - iletisim"
              className=" bg-transparent border border-white px-2 rounded-sm md:text-3xl md:px-10 md:py-2"
            >
              Teklif Al
            </NavLink>
          </div>
        </div>
      </div>
    </Slider>
  );
}

export default SliderImg;
