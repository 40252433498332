import React from "react";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
function Footer() {
  const headlines = [
    "Teklif isteyin",
    "En iyi fiyatı alın",
    "Evinizi taşıyalım",
    "Ofisinizi Taşıyalım",
  ];
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Önce opaklığı sıfıra düşür
      setTimeout(() => {
        setIndex((prev) => (prev + 1) % headlines.length); // Başlığı değiştir
        setFade(false); // Sonra tekrar görünür yap
      }, 500); // Animasyon süresi
    }, 2500); // Her 5 saniyede bir değiş

    return () => clearInterval(interval); // Temizleme
  }, []);

  return (
    <footer className="bg-[#232121]  text-white">
      <div className="bg-yellow-500 text-black flex flex-col items-center space-y-10 text-center mt-10 py-10 px-5">
        <h2 className="text-white text-3xl">
          ÖzFilo Evden Eve Nakliyat Güvencesiyle
        </h2>
        <h2
          className={`text-2xl font-semibold transition-all duration-500  ${
            fade ? "opacity-0 translate-y-4" : "opacity-100 translate-y-0"
          }`}
        >
          {headlines[index]}
        </h2>
        <div className="flex space-x-3">
          <NavLink className="block bg-gray-800 text-xl text-white px-8 py-3 border border-white rounded-lg">
            Teklif Alın
          </NavLink>
          <NavLink className="block bg-gray-800 text-xl text-white px-8 py-3 border border-white rounded-lg">
            Telefon Edin
          </NavLink>
        </div>
        <p>
          <strong>Özfilo Evden Eve Nakliyat Firması</strong> olarak;
          <strong>İstanbul şehir içi nakliyat</strong> ve tüm Türkiye genelinde
          <strong>şehirler arası nakliyat</strong> hizmetleri sunmaktayız.1995
          yılından bu yana
          <em>
            <strong>evden eve nakliyat</strong>
          </em>
          hizmeti veriyoruz. Ayrıca
          <strong>
            parça eşya taşıma, eşya depolama, asansörlü nakliyat ve ev
            taşımacılığının yanı sıra ofis taşıma, işyeri taşıma ve fabrika
            taşıma
          </strong>
          hizmetlerini de profesyonel ve özverili ekibimizle sağlamaktayız.
        </p>
      </div>
      <div className="p-10">
        <div className="  flex flex-col lg:flex-row lg:space-x-10 lg:justify-evenly lg:space-y-0 space-y-10">
          <div className="lg:w-1/3">
            <h3 className="my-5">HİZMETLER</h3>
            <div className="flex flex-col space-y-2">
              <NavLink
                to={"/evden-eve-tasimacilik"}
                title="evden-eve-nakliyat"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Evden Eve Nakliyat
              </NavLink>
              <NavLink
                to={"/sehirler-arasi-nakliyat"}
                title="sehirler-arasi-nakliyat"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Şehirler Arası Nakliyat
              </NavLink>
              <NavLink
                to={"/sehir-ici-nakliyat"}
                title="sehir-ici-nakliyat"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Şehir İçi Nakliyat
              </NavLink>
              <NavLink
                to={"/parca-esya-tasima"}
                title="parca-esya-tasima"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Parça Eşya Taşıma
              </NavLink>
              <NavLink
                to={"/ofis-tasima"}
                title="ofis-tasima"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Ofis Taşıma
              </NavLink>
              <NavLink
                to={"/esya-depolama"}
                title="esya-depolama"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Eşya Depolama
              </NavLink>
              <NavLink
                to={"/asansorlu-esya-tasima"}
                title="asansorlu-esya-tasima"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Asansörlü Eşya Taşıma
              </NavLink>
              <NavLink
                to={"/sigortali-nakliyat"}
                title="sigortali-nakliyat"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Sigortalı Nakliyat
              </NavLink>
              <NavLink
                to={"/komple-bina-tasima"}
                title="komple-bina-nakliyati"
                className="flex items-center "
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Komple Bina Nakliyatı
              </NavLink>
            </div>
          </div>
          <div className="lg:w-1/3">
            <h3 className="my-5">HIZLI ERİŞİM</h3>
            <div className="flex flex-col space-y-2">
              <NavLink
                to={"/"}
                title="ozfilo-nakliyat"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Anasayfa
              </NavLink>
              <NavLink
                to={"/hakkimizda"}
                title="ozfilo-nakliyat-hakkinda"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Hakkımızda
              </NavLink>
              <NavLink
                to={"/hizmetlerimiz"}
                title="ozfilo-nakliyat-hizmetlerimiz"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Hizmetlerimiz
              </NavLink>
              <NavLink
                to={"/iletisim"}
                title="ozfilo-nakliyat-iletisim"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                İletişim
              </NavLink>
              <NavLink
                to={"/blog"}
                title="ozfilo-nakliyatı-blog"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Blog
              </NavLink>
              <NavLink
                to={"tel:05323620016"}
                title="ozfilo-fiyat-teklifi"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Nakliyat Fiyat Teklifi
              </NavLink>
              <NavLink
                to={"/nasil-yapiyoruz"}
                title="ozfilo-nasil-yapiyoruz"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Nasıl Yapıyoruz
              </NavLink>
              <NavLink
                to={"/cerez-gizlilik-polikitasi"}
                title="oziflo"
                className="flex items-center "
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 256 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
                Çerez Ve Gizlilik Politikası
              </NavLink>
            </div>
          </div>
          <div className="lg:w-1/3">
            <h3 className="my-5">İLETİŞİM BİLGİLERİMİZ</h3>
            <div className="flex flex-col space-y-2">
              <p className="text-center">
                Bağlarbaşı Mahallesi Bağdat Caddesi Doğanlar Pasajı No.459/17
                Maltepe / İstanbul
              </p>
              <NavLink
                title="ozfilo-nasil-yapiyoruz"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 320 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"></path>
                </svg>
                0532 362 00 16
              </NavLink>
              <NavLink
                title="ozfilo-nasil-yapiyoruz"
                className="flex items-center border-b-2 border-yellow-500"
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                </svg>
                0532 350 88 51
              </NavLink>
              <NavLink
                title="ozfilo-nasil-yapiyoruz"
                className="flex items-center "
              >
                <svg
                  aria-hidden="true"
                  width="25px"
                  height="25px"
                  className=" fill-yellow-500"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-16.39 307.37l-15 65A15 15 0 0 1 354 416C194 416 64 286.29 64 126a15.7 15.7 0 0 1 11.63-14.61l65-15A18.23 18.23 0 0 1 144 96a16.27 16.27 0 0 1 13.79 9.09l30 70A17.9 17.9 0 0 1 189 181a17 17 0 0 1-5.5 11.61l-37.89 31a231.91 231.91 0 0 0 110.78 110.78l31-37.89A17 17 0 0 1 299 291a17.85 17.85 0 0 1 5.91 1.21l70 30A16.25 16.25 0 0 1 384 336a17.41 17.41 0 0 1-.39 3.37z"></path>
                </svg>
                0212 266 04 55
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex justify-evenly my-5">
          <NavLink>
            <svg
              width="25px"
              height="25px"
              className=" fill-gray-300"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
            </svg>
          </NavLink>
          <NavLink>
            <svg
              width="25px"
              height="25px"
              className=" fill-gray-300"
              viewBox="0 0 576 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
            </svg>
          </NavLink>
          <NavLink>
            <svg
              width="25px"
              height="25px"
              className=" fill-gray-300"
              viewBox="0 0 496 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
            </svg>
          </NavLink>
          <NavLink>
            <svg
              width="25px"
              height="25px"
              className=" fill-gray-300"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
            </svg>
          </NavLink>
        </div>
        <div>
          <h3 className="text-xl">ÇALIŞMA SAATLERİMİZ</h3>
          <div className="flex p-3 font-thin">
            <div className="w-1/2 text-sm/7">
              PAZARTESİ <br />
              SALI <br />
              ÇARŞAMBA <br />
              PERŞEMBE <br />
              CUMA <br />
              CUMARTESİ <br />
              PAZAR <br />
            </div>
            <div className="w-1/2 text-sm/7">
              24 SAAT <br />
              24 SAAT <br />
              24 SAAT <br />
              24 SAAT <br />
              24 SAAT <br />
              24 SAAT <br />
              24 SAAT <br />
            </div>
          </div>
        </div>
        <div className="text-center">
          © Copright - Tüm Hakları Saklıdır. 1995 - 2025
          <NavLink
            href="https://ozfilonakliyat.com/"
            title="ÖzFilo Evden Eve Nakliyat"
          >
            ÖzFilo Evden Eve Nakliyat
          </NavLink>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
