import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./Pages/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./components/Footer";
import Hakkimizda from "./Pages/Hakkimizda";
import Hizmetlerimiz from "./Pages/Hizmetlerimiz";
import Blog from "./Pages/Blog";
import Iletisim from "./Pages/Iletisim";
import EvdenEve from "./Pages/Hizmetler/EvdenEve";
import SehirlerArasi from "./Pages/Hizmetler/SehirlerArasi";
import Sehirİci from "./Pages/Hizmetler/Sehirİci";
import Ofistas from "./Pages/Hizmetler/Ofistas";
import Asansorlu from "./Pages/Hizmetler/Asansorlu";
import ParcaEsya from "./Pages/Hizmetler/ParcaEsya";
import EsyaDepo from "./Pages/Hizmetler/EsyaDepo";
import BinaNakliyat from "./Pages/Hizmetler/BinaNakliyat";
function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hakkimizda" element={<Hakkimizda />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/iletisim" element={<Iletisim />} />
        <Route path="/hizmetlerimiz" element={<Hizmetlerimiz />} />

        <Route path="/evden-eve-tasimacilik" element={<EvdenEve />} />
        <Route path="/sehirler-arasi-nakliyat" element={<SehirlerArasi />} />
        <Route path="/sehir-ici-nakliyat" element={<Sehirİci />} />
        <Route path="/ofis-tasima" element={<Ofistas />} />
        <Route path="/asansorlu-esya-tasima" element={<Asansorlu />} />
        <Route path="/parca-esya-tasima" element={<ParcaEsya />} />
        <Route path="/esya-depolama" element={<EsyaDepo />} />
        <Route path="/komple-bina-tasima" element={<BinaNakliyat />} />
        <Route path="/sigortali-nakliyat" element={<EvdenEve />} />

        <Route path="/rezidans-daire-nakliyat" element={<EvdenEve />} />
        <Route path="/rezidans-ofis-nakliyat" element={<EvdenEve />} />
        <Route path="/rezidans-ofis-nakliyat" element={<EvdenEve />} />
        <Route path="/malikane-nakliyat" element={<EvdenEve />} />
        <Route path="/malikane-nakliyat" element={<EvdenEve />} />
        <Route path="/paketleme" element={<EvdenEve />} />
        <Route path="/referanslarimiz" element={<Blog />} />
        <Route path="/piyano-nakliyat" element={<EvdenEve />} />
        <Route path="/antika-esya-nakliyat" element={<EvdenEve />} />
        <Route path="/villa-nakliyat" element={<EvdenEve />} />
        <Route path="/yali-nakliyat" element={<EvdenEve />} />

        <Route path="/sehir-disi-ev-tasima" element={<SehirlerArasi />} />
        <Route path="/sultanbeyli-evden-eve-nakliyat" element={<Sehirİci />} />
        <Route path="/pendik-evden-eve-nakliyat" element={<Sehirİci />} />
        <Route path="/sancaktepe-evden-eve-nakliyat" element={<Sehirİci />} />
        <Route path="/kartal-evden-eve-nakliyat" element={<Sehirİci />} />
        <Route path="/pendik-evden-eve-nakliyat" element={<Sehirİci />} />
        <Route path="/sehir-disi-eve-nakliyat" element={<SehirlerArasi />} />

        <Route path="/nasil-yapiyoruz" element={<EvdenEve />} />
        <Route path="/cerez-gizlilik-polikitasi" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
