import React from "react";
import img1 from "../../img/Hizmetler/parca-esya-tasima.jpeg";
import { NavLink } from "react-router-dom";
function ParcaEsya() {
  return (
    <div>
      <h1 className="text-5xl text-center mt-10">ÖzFilo - Parça Eşya Taşıma</h1>
      <img
        src={img1}
        alt="ÖzFilo - Parça Eşya Taşıma"
        className="mx-auto w-[800px] my-10"
      />
      <div className="text-gray-600 p-5 flex flex-col space-y-10 my-10">
        <div className="flex flex-col space-y-5">
          <p>
            <strong>Özfilo Evden Eve Nakliyat</strong> <strong>İstanbul</strong>{" "}
            genelinde <strong> 2.999 TL’den</strong>
            &nbsp;&nbsp;<strong>parça eşya taşıma </strong>konusunda daha da
            ileriye giderek küçük kapasiteli araç filolarımızı yenilemiş
            bulunuyoruz. <strong>İstanbul Parça Eşya Taşıma</strong> ve{" "}
            <strong>Şehirlerarası Parça Eşya taşıma</strong> hizmetlerimiz
            mevcuttur. Tüm Türkiye geneline 72 saatte parça eşyalar adresinize
            teslim edilmektedir. Ayrıca İstanbul Adalar başta olmak üzere bütün
            ilçe ve semtlere gün içinde kesin bir ulaşım hizmeti vermekteyiz.
            Rakiplerimiz karşısında ise çok uygun fiyatlarla müşterilerimize en
            kolay ve çabuk bir taşıma hizmeti veriyoruz.&nbsp;
          </p>
          <p>
            <strong>Parça eşya taşıma hizmeti</strong> diğer bir deyişle{" "}
            <strong>parsiyel taşımacılık</strong>{" "}
            <em>
              <strong>
                şehir içi parça eşya taşıma ve şehirler arası parça eşya taşıma
              </strong>
            </em>{" "}
            olarak ikiye ayrılır. Özfilo Evden Eve Nakliyat olarak{" "}
            <strong>Türkiye geneli</strong> parça eşya taşıma hizmetlerini{" "}
            <strong>ekonomik fiyatlar</strong>la sunmaktayız.
          </p>
          <p>
            <strong>İstanbul’daki parça eşya taşıma</strong> ihtiyaçlarınız için
            rakiplerimiz karşısında çok uygun fiyatlar sunuyoruz. Fiyatlarımız{" "}
            <strong>2.999 TL</strong>‘den başlamaktadır ve kaliteli hizmetimizle
            bu fiyatın karşılanamayacağı bir ihtiyacın olmadığını garanti
            ediyoruz.
          </p>
          <p>
            <strong>Parça eşya taşıma hizmetinde ekonomik fiyatlarla</strong>{" "}
            kaliteli bir deneyim yaşamak için mutlaka bize ulaşın. Bizden fiyat
            almadan karar vermeyin!
          </p>
          <p>
            <em>Saygılarımızla,</em>
          </p>
          <p>
            <em> Özfilo Evden Eve Nakliyat</em>
          </p>{" "}
        </div>
        <div className="flex flex-col md:flex-row ">
          <NavLink
            to={"tel:05323620016"}
            title="özfilo - iletişim"
            className="flex items-center lg:w-2/4 xl:w-1/4 mx-auto my-5 justify-center group hover:text-blue-700 hover:border-blue-700 hover:border-2 gap-3 p-3 border border-gray-600 text-gray-600 text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-gray-600 group-hover:fill-blue-700"
              width="30px"
              height="30px"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
            </svg>
            0532 362 0016
          </NavLink>
          <NavLink
            to={
              "https://wa.me/905323508851?text=Merhaba%20Nakliye%20Hakk%C4%B1nda%20Bilgi%20Almak%20%C4%B0stiyorum"
            }
            title="özfilo - iletişim"
            className="flex my-5 bg-[#25D366] lg:w-2/4 xl:w-1/4 mx-auto items-center justify-center group  hover:border-black hover:border-2 gap-3 p-3 border border-black text-white text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-white"
              width="30px"
              height="30px"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
            </svg>
            0532 350 88 51
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default ParcaEsya;
