import React from "react";
import SliderImg from "../components/SliderImg";
import { NavLink } from "react-router-dom";

import ozfilocars from "../img/ozfilo-cars.jpeg";

import ref1 from "../img/istanbul-sehirler-arasi-nakliyat-1.jpg";
import ref2 from "../img/istanbul-sehirler-arasi-nakliyat.jpg";
import ref3 from "../img/sehirler-arasi-nakliyat.jpg";

import memurlar from "../img/memurlara-ev-tasima-indirimi.jpg";

import blog1 from "../img/Blog/kartal-evden-eve-nakliyat-768x480.jpg";
import blog2 from "../img/Blog/kartal-sehir-ici-esya-tasima.jpg";
import blog3 from "../img/Blog/markus-spiske-XrIfY_4cK1w-unsplash-768x512.jpg";
import blog4 from "../img/Blog/pendik-evden-eve-nakliyat-768x480.jpg";
import blog5 from "../img/Blog/pendik-sehir-ici-nakliyat-768x480.jpg";
import blog6 from "../img/Blog/sancaktepe-sehir-ici-nakliyat-768x480.jpg";
import blog7 from "../img/Blog/sehir-disi-ev-tasima-firmasi.jpg";
import blog8 from "../img/Blog/sehir-disi-evden-eve-nakliyat-sirketi.jpg";
import blog9 from "../img/Blog/sultanbeyli-sehir-ici-nakliyat-768x480.jpg";
function Home() {
  return (
    <div className="flex flex-col gap-20">
      <SliderImg />
      <section className="flex flex-col space-y-10 p-5 ">
        <h1 className=" text-2xl md:text-5xl">
          Neden ÖzFilo Evden Eve Nakliyat ?
        </h1>
        <p className="  text-sm/7 md:text-xl md:text-gray-600">
          İşinde uzman ve özverili kadromuzla 26+ yıldır müşteri memnuniyeti
          için çabalıyor ve ekonomik fiyatlarla hizmet sunuyoruz. Şehir içi
          nakliyat ve şehirler arası nakliyat konusunda çok iddialıyız. Evden
          eve nakliyat hizmetimize ek olarak asansörlü taşıma, vinçle eşya
          taşıma, eşya depolama, parça eşya taşıma gibi bir çok kaliteli
          hizmetleri uygun fiyatlı sunmaktayız. Özfilo nakliyat olarak ev
          taşımanın yanı sıra ofis taşıma ve işyeri taşıma ayrı rezidans ofis ve
          işyeri eşya taşıması da yapıyoruz. <br /> Tüm bu taşınma işlemleri
          için fiyatlarımız 2.999 TL‘den başlamaktadır ve kaliteli hizmetimizle
          bu fiyatın karşılanamayacağı bir ihtiyacın olmadığını garanti
          ediyoruz.
        </p>
        <NavLink
          className="w-1/4 mx-auto bg-blue-500 flex justify-center items-center text-center py-1 md:py-3 md:text-3xl rounded-md text-white font-semibold"
          to="tel:05323620016"
        >
          <svg
            height="50px"
            width="50px"
            aria-hidden="true"
            className="fill-white"
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-16.39 307.37l-15 65A15 15 0 0 1 354 416C194 416 64 286.29 64 126a15.7 15.7 0 0 1 11.63-14.61l65-15A18.23 18.23 0 0 1 144 96a16.27 16.27 0 0 1 13.79 9.09l30 70A17.9 17.9 0 0 1 189 181a17 17 0 0 1-5.5 11.61l-37.89 31a231.91 231.91 0 0 0 110.78 110.78l31-37.89A17 17 0 0 1 299 291a17.85 17.85 0 0 1 5.91 1.21l70 30A16.25 16.25 0 0 1 384 336a17.41 17.41 0 0 1-.39 3.37z"></path>
          </svg>
          <p>Teklif Alın</p>
        </NavLink>
      </section>
      <div className="md:w-full border-2 border-gray-400 w-11/12 rounded-full mx-auto"></div>
      <section className="flex flex-col space-y-10 p-5 ">
        <img src={ozfilocars} alt="özfilo-evden-eve-nakliyat" />
        <h2 className=" text-xl mx-auto md:text-5xl">
          ÖzFilo Evden Eve Nakliyat
        </h2>
        <div className=" flex flex-col md:flex-row md:space-y-0 md:space-x-3 items-center space-y-14">
          <div className=" flex flex-col space-y-3 justify-center text-center items-center">
            <svg
              aria-hidden="true"
              height="50px"
              className=" fill-blue-500 hover:fill-blue-600"
              viewBox="0 0 384 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M97.12 362.63c-8.69-8.69-4.16-6.24-25.12-11.85-9.51-2.55-17.87-7.45-25.43-13.32L1.2 448.7c-4.39 10.77 3.81 22.47 15.43 22.03l52.69-2.01L105.56 507c8 8.44 22.04 5.81 26.43-4.96l52.05-127.62c-10.84 6.04-22.87 9.58-35.31 9.58-19.5 0-37.82-7.59-51.61-21.37zM382.8 448.7l-45.37-111.24c-7.56 5.88-15.92 10.77-25.43 13.32-21.07 5.64-16.45 3.18-25.12 11.85-13.79 13.78-32.12 21.37-51.62 21.37-12.44 0-24.47-3.55-35.31-9.58L252 502.04c4.39 10.77 18.44 13.4 26.43 4.96l36.25-38.28 52.69 2.01c11.62.44 19.82-11.27 15.43-22.03zM263 340c15.28-15.55 17.03-14.21 38.79-20.14 13.89-3.79 24.75-14.84 28.47-28.98 7.48-28.4 5.54-24.97 25.95-45.75 10.17-10.35 14.14-25.44 10.42-39.58-7.47-28.38-7.48-24.42 0-52.83 3.72-14.14-.25-29.23-10.42-39.58-20.41-20.78-18.47-17.36-25.95-45.75-3.72-14.14-14.58-25.19-28.47-28.98-27.88-7.61-24.52-5.62-44.95-26.41-10.17-10.35-25-14.4-38.89-10.61-27.87 7.6-23.98 7.61-51.9 0-13.89-3.79-28.72.25-38.89 10.61-20.41 20.78-17.05 18.8-44.94 26.41-13.89 3.79-24.75 14.84-28.47 28.98-7.47 28.39-5.54 24.97-25.95 45.75-10.17 10.35-14.15 25.44-10.42 39.58 7.47 28.36 7.48 24.4 0 52.82-3.72 14.14.25 29.23 10.42 39.59 20.41 20.78 18.47 17.35 25.95 45.75 3.72 14.14 14.58 25.19 28.47 28.98C104.6 325.96 106.27 325 121 340c13.23 13.47 33.84 15.88 49.74 5.82a39.676 39.676 0 0 1 42.53 0c15.89 10.06 36.5 7.65 49.73-5.82zM97.66 175.96c0-53.03 42.24-96.02 94.34-96.02s94.34 42.99 94.34 96.02-42.24 96.02-94.34 96.02-94.34-42.99-94.34-96.02z"></path>
            </svg>
            <h3 className="text-2xl md:text-3xl">Hizmet Kalitemiz</h3>
            <p className="text-md/7 text-gray-600">
              Sahip olduğunuz tüm eşyaların taşınması söz konusu olduğunda,
              boyutları ne olursa olsun, size yardımcı olabilecek tek ortağız!
            </p>
          </div>
          <div className=" flex flex-col space-y-3  justify-center text-center items-center">
            <svg
              className=" fill-blue-500 hover:fill-blue-600"
              height="50px"
              aria-hidden="true"
              viewBox="0 0 640 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"></path>
            </svg>
            <h3 className="text-2xl md:text-3xl">Uzman Kadro</h3>
            <p className="text-md/7 text-gray-600">
              Taşıma şirketi ekibimizin tüm üyeleri, taşıma sürecini sizin ve
              eşyalarınız için kolay bir yolculuk haline getirmeye tamamen
              hazır, donanımlı ve işlerinde uzman!
            </p>
          </div>
          <div className=" flex flex-col  space-y-3 justify-center text-center items-center">
            <svg
              aria-hidden="true"
              height="50px"
              className=" fill-blue-500 hover:fill-blue-600"
              viewBox="0 0 384 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M371.994 256h-48.019C317.64 256 312 260.912 312 267.246 312 368 230.179 416 144 416V256.781l134.603-29.912A12 12 0 0 0 288 215.155v-40.976c0-7.677-7.109-13.38-14.603-11.714L144 191.219V160.78l134.603-29.912A12 12 0 0 0 288 119.154V78.179c0-7.677-7.109-13.38-14.603-11.714L144 95.219V44c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v68.997L9.397 125.131A12 12 0 0 0 0 136.845v40.976c0 7.677 7.109 13.38 14.603 11.714L64 178.558v30.439L9.397 221.131A12 12 0 0 0 0 232.845v40.976c0 7.677 7.109 13.38 14.603 11.714L64 274.558V468c0 6.627 5.373 12 12 12h79.583c134.091 0 223.255-77.834 228.408-211.592.261-6.782-5.211-12.408-11.997-12.408z"></path>
            </svg>
            <h3 className="text-2xl md:text-3xl">Uygun Fiyat</h3>
            <p className="text-md/7 text-gray-600">
              Şirket olarak maksimum hizmet minimum ücret politikasını
              benimsiyoruz! Bu sebepten dolayı fiyatlarımızı 2.999 TL den
              başlatıyoruz!
            </p>
          </div>
        </div>
      </section>
      <section className=" flex flex-col space-y-5 text-center p-5">
        <h2 className="text-4xl font-bold md:text-5xl">Evden Eve Nakliyat</h2>
        <p className=" text-md/7 text-gray-600">
          <strong>Evden eve nakliyat</strong> ihtiyaçlarınız için profesyonel
          bir firma tercih etmek çok önemlidir. Taşınmak gibi sıkıntılı ve
          stresli bir süreci sorunsuz bir şekilde atlatabilmek adına, firma
          kalitesine dikkat edilmelidir. Bu anlamda Özfilo Evden Eve Nakliyat
          olarak, müşterilerimize kusursuz bir taşınma deneyimi yaşatmaktayız.
          26 yıllık deneyimin yanı sıra her zaman müşteri memnuniyeti ilkesi
          doğrultusunda hareket etmekteyiz. Firmamızın sektöründe lider bir
          konumda olmasının temel nedeni tamamen olumlu geri dönüşlerdir.
          Türkiye genelinde <strong>şehir içi evden eve nakliyat</strong> ve{" "}
          <strong>şehirler arası evden eve nakliyat</strong> hizmetlerini
          özverili ve işinde uzman ekip arkadaşlarımızla sağlamaktayız.
        </p>
        <h2 className="text-4xl font-bold">Şehir İçi Evden Eve Nakliyat</h2>
        <p className=" text-md/7 text-gray-600">
          İstanbul başta olmak üzere ülke genelinde{" "}
          <strong>şehir içi evden eve nakliyat</strong> hizmeti vermekteyiz.{" "}
          <strong>Şehir içi nakliyat</strong> konusunda 1995 yılından bu yana
          bir hayli tecrübemiz birikti ve son model teknolojik ekipmanlarımız
          bulunmaktadır.&nbsp;
        </p>
        <h2 className="text-4xl font-bold">
          Şehirler Arası Evden Eve Nakliyat
        </h2>
        <p className=" text-md/7 text-gray-600">
          <strong>Şehirler arası evden eve nakliyat</strong> gayet zorlukları
          olan bir işlemler sürecidir. Ev eşyalarınızın{" "}
          <strong>şehirler arası nakliyat</strong> sırasında zarar görme
          ihtimali daha fazla olacağından paketleme işlemlerini daha fazla yapa
          ve daha titiz yapmaktayız. Ayrıca Eşyalarınız Özfilo evden eve
          nakliyat aracına yüklenir. her türlü hesap yapılır ve en iyi yerleşim
          düzeniyle yerleştirilir. Her hangi bir olumsuz duruma karşın yine de
          taşıma boyunca <em>eşyalarınız sigortalanır</em> ve her hangi bir
          hasar durumunda hasarınız tazmin edilir.
        </p>
      </section>
      <NavLink className=" border flex space-x-3 w-72 md:w-96 md:justify-center md:text-2xl items-center mx-auto border-gray-500 px-5 py-2 font-semibold text-gray-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          className=" fill-gray-500 w-8 h-6 md:w-10 md:h-8"
        >
          <path d="M280-160q-50 0-85-35t-35-85H60l18-80h113q17-19 40-29.5t49-10.5q26 0 49 10.5t40 29.5h167l84-360H182l4-17q6-28 27.5-45.5T264-800h456l-37 160h117l120 160-40 200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H400q0 50-35 85t-85 35Zm357-280h193l4-21-74-99h-95l-28 120Zm-19-273 2-7-84 360 2-7 34-146 46-200ZM20-427l20-80h220l-20 80H20Zm80-146 20-80h260l-20 80H100Zm180 333q17 0 28.5-11.5T320-280q0-17-11.5-28.5T280-320q-17 0-28.5 11.5T240-280q0 17 11.5 28.5T280-240Zm400 0q17 0 28.5-11.5T720-280q0-17-11.5-28.5T680-320q-17 0-28.5 11.5T640-280q0 17 11.5 28.5T680-240Z" />
        </svg>
        <p>Evden Eve Nakliyat</p>
      </NavLink>
      <section className=" ">
        <h3 className=" text-5xl text-center mb-5">Referanslarımız</h3>
        <div className="md:flex">
          <div className=" flex flex-col  space-y-3 items-center m-3">
            <img
              src={ref1}
              alt="güvenli-esya-tasıma"
              className=" w-60 rounded-lg "
            />
            <p>
              Taşınma esnasında siz değerli müşterilerimizin eşyalarına zarar
              vermemek için özenle taşıma sağlayan firmamız müşterimiz Korhan
              Bey tarafından tam puan aldı.
            </p>
            <br />
            <p>
              Korhan Bey “
              <em>
                Ben tercih ettim çok memnun kaldım size de tavsiye ederim…
              </em>
              ” demekte.
            </p>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
            </div>
          </div>

          <div className=" flex flex-col space-y-3 items-center m-3">
            <img
              src={ref2}
              alt="güvenli-depo-tasıma"
              className=" w-60 rounded-lg "
            />
            <p>
              Taşınma esnasında siz değerli müşterilerimizin eşyalarını harika
              bir istif sağlayan ekibimiz yolculuk esnasında eşyalarınızın zarar
              görmesini engeller.
            </p>
            <br />
            <p>
              Canan Hanım “
              <em>
                Eşyalarımı gözüm kapalı teslim ederim çok özenli bir çalışma…
              </em>
              ” demekte.
            </p>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
            </div>
          </div>

          <div className=" flex flex-col space-y-3 items-center m-3 font-thin ">
            <img
              src={ref3}
              alt="güvenli-ofis-tasıma"
              className=" w-60 rounded-lg "
            />
            <p>
              Taşınma esnasında siz değerli müşterilerimizin eşyalarına zarar
              vermemek için özenle paketleme sağlayan firmamız müşterimiz Ersin
              Bey tarafından tam puan aldı.
            </p>
            <br />
            <p>
              Ersin Bey “
              <em>
                Taşıma esnasında sağlam paketleme olduğu için çizik dahi olmadı…
              </em>
              ” demekte.
            </p>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="40px"
                className=" fill-yellow-400"
              >
                <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
              </svg>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col lg:gap-10 items-center space-y-10 md:space-y-0 text-center ">
        <h3 className=" text-5xl">Hizmetlerimiz</h3>
        <p>
          <strong>ÖzFilo Evden Eve Nakliyat</strong> olarak Türkiye genelinde{" "}
          <em>
            <strong>
              evden eve nakliyat, eşya taşıma, parça eşya taşıma, şehirler arası
              nakliyat, şehir içi nakliyat, ofis taşıma, işyeri taşıma,
              asansörlü eşya taşıma, asansörlü nakliyat ve eşya depolama
            </strong>
          </em>{" "}
          hizmetleri sunmaktayız. Nakliye hizmetlerimizde tecrübeli ve özverili
          ekip arkadaşlarımızla güvenli ve{" "}
          <strong>sigortalı taşımacılık</strong> hizmetleri sunmaktayız.
        </p>
        <div className="flex flex-col space-y-10 md:space-y-0 md:flex-row  lg:gap-0 lg:w-3/4  md:justify-evenly md:gap-x-1 md:gap-y-5 md:flex-wrap">
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="60px"
                height="200px"
                className="fill-white mx-auto"
              >
                <path d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z" />
              </svg>
              <h3 className="text-2xl">Evden Eve Nakliyat</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Evden Eve Nakliyat</h3>
              <p className="text-center">
                Türkiye Genelinde <strong> Evden Eve Nakliyat</strong>{" "}
                Hizmetlerini En Uygun Fiyatlarla ve Müşteri Memnuniyeti Odaklı
                Sunmaktayız.
              </p>
              <NavLink
                to={"/evden-eve-tasimacilik"}
                title="evden-eve-taşımacılık"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                width="60px"
                height="200px"
                className="fill-white mx-auto"
                viewBox="0 0 640 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M621.3 237.3l-58.5-58.5c-12-12-28.3-18.7-45.3-18.7H480V64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v336c0 44.2 35.8 80 80 80 26.3 0 49.4-12.9 64-32.4 14.6 19.6 37.7 32.4 64 32.4 44.2 0 80-35.8 80-80 0-5.5-.6-10.8-1.6-16h163.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16H624c8.8 0 16-7.2 16-16v-85.5c0-17-6.7-33.2-18.7-45.2zM80 432c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm128 0c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm272-224h37.5c4.3 0 8.3 1.7 11.3 4.7l43.3 43.3H480v-48zm48 224c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32z"></path>
              </svg>
              <h3 className="text-2xl">Şehirler Arası Nakliyat</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Şehirler Arası Nakliyat</h3>
              <p className="text-center">
                <strong> Şehirler Arası Nakliyat</strong> Firması Olarak
                Zamanlama Ve Hassasiyet Konularına Önemseyerek Hizmet
                Vermekteyiz.
              </p>
              <NavLink
                to={"/sehirler-arasi-nakliyat"}
                title="şehirler-arası-nakliyat"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                width="60px"
                height="200px"
                className="fill-white mx-auto"
                viewBox="0 0 640 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M50.2 375.6c2.3 8.5 11.1 13.6 19.6 11.3l216.4-58c8.5-2.3 13.6-11.1 11.3-19.6l-49.7-185.5c-2.3-8.5-11.1-13.6-19.6-11.3L151 133.3l24.8 92.7-61.8 16.5-24.8-92.7-77.3 20.7C3.4 172.8-1.7 181.6.6 190.1l49.6 185.5zM384 0c-17.7 0-32 14.3-32 32v323.6L5.9 450c-4.3 1.2-6.8 5.6-5.6 9.8l12.6 46.3c1.2 4.3 5.6 6.8 9.8 5.6l393.7-107.4C418.8 464.1 467.6 512 528 512c61.9 0 112-50.1 112-112V0H384zm144 448c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"></path>
              </svg>
              <h3 className="text-2xl">Ofis & İşyeri Taşıma</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Ofis & İşyeri Taşıma</h3>
              <p className="text-center">
                <strong> Ofis Taşıma</strong> Ve Her Türlü{" "}
                <strong> İş Yeri Taşıma</strong> Hizmetlerini Profesyonel Ve
                Tecrübeli Ekibimizle Gerçekleştirmekteyiz.{" "}
              </p>
              <NavLink
                to={"/ofis-tasima"}
                title="ofis-taşıma"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                width="60px"
                height="200px"
                className="fill-white mx-auto"
                viewBox="0 0 640 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M616 192H480V24c0-13.26-10.74-24-24-24H312c-13.26 0-24 10.74-24 24v72h-64V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v80h-64V16c0-8.84-7.16-16-16-16H80c-8.84 0-16 7.16-16 16v80H24c-13.26 0-24 10.74-24 24v360c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V216c0-13.26-10.75-24-24-24zM128 404c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm128 192c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm160 96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12V76c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm160 288c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40z"></path>
              </svg>
              <h3 className="text-2xl">Şehir İçi Nakliyat</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Şehir İçi Nakliyat</h3>
              <p className="text-center">
                <strong>Şehir İçi Nakliyat</strong> Hizmetimiz İstanbul İlini
                Kapsamaktadır. En Hızlı Ve Güvenli Şekilde En Uygun Fiyatlarla
                Hizmetinizdeyiz.
              </p>
              <NavLink
                to={"/sehir-ici-nakliyat"}
                title="şehir-içi-nakliyat"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                width="60px"
                height="200px"
                className="fill-white mx-auto"
                viewBox="0 0 640 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M608 320h-64v64h22.4c5.3 0 9.6 3.6 9.6 8v16c0 4.4-4.3 8-9.6 8H73.6c-5.3 0-9.6-3.6-9.6-8v-16c0-4.4 4.3-8 9.6-8H96v-64H32c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32h576c17.7 0 32-14.3 32-32v-96c0-17.7-14.3-32-32-32zm-96 64V64.3c0-17.9-14.5-32.3-32.3-32.3H160.4C142.5 32 128 46.5 128 64.3V384h384zM211.2 202l25.5-25.3c4.2-4.2 11-4.2 15.2.1l41.3 41.6 95.2-94.4c4.2-4.2 11-4.2 15.2.1l25.3 25.5c4.2 4.2 4.2 11-.1 15.2L300.5 292c-4.2 4.2-11 4.2-15.2-.1l-74.1-74.7c-4.3-4.2-4.2-11 0-15.2z"></path>
              </svg>
              <h3 className="text-2xl">Eşya Depolama</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Eşya Depolama</h3>
              <p className="text-center">
                Kısa Veya Uzun Süreli <strong> Eşya Depolama</strong> Hizmeti
                Sunmaktayız. Eşyalarınız Güvenli Depolarımızda Saklanmaktadır.{" "}
              </p>
              <NavLink
                to={"/esya-depolama"}
                title="eşya-depolama"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                width="60px"
                height="200px"
                className="fill-white mx-auto"
                viewBox="0 0 576 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M519.442 288.651c-41.519 0-59.5 31.593-82.058 31.593C377.409 320.244 432 144 432 144s-196.288 80-196.288-3.297c0-35.827 36.288-46.25 36.288-85.985C272 19.216 243.885 0 210.539 0c-34.654 0-66.366 18.891-66.366 56.346 0 41.364 31.711 59.277 31.711 81.75C175.885 207.719 0 166.758 0 166.758v333.237s178.635 41.047 178.635-28.662c0-22.473-40-40.107-40-81.471 0-37.456 29.25-56.346 63.577-56.346 33.673 0 61.788 19.216 61.788 54.717 0 39.735-36.288 50.158-36.288 85.985 0 60.803 129.675 25.73 181.23 25.73 0 0-34.725-120.101 25.827-120.101 35.962 0 46.423 36.152 86.308 36.152C556.712 416 576 387.99 576 354.443c0-34.199-18.962-65.792-56.558-65.792z"></path>
              </svg>
              <h3 className="text-2xl">Parça Eşya Taşıma</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Parça Eşya Taşıma</h3>
              <p className="text-center">
                Adet Sınırı Olmadan Tüm Türkiye Geneli{" "}
                <strong> Parça Eşya Taşıma</strong> Hizmetini En Ekonomik
                Fiyatlar Ve En Güvenli Şekilde Taşıyoruz.{" "}
              </p>
              <NavLink
                to={"/parca-esya-tasima"}
                title="parça-eşya-taşıma"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                width="60px"
                height="200px"
                className="fill-white mx-auto"
                viewBox="0 0 320 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M313.553 119.669L209.587 7.666c-9.485-10.214-25.676-10.229-35.174 0L70.438 119.669C56.232 134.969 67.062 160 88.025 160H152v272H68.024a11.996 11.996 0 0 0-8.485 3.515l-56 56C-4.021 499.074 1.333 512 12.024 512H208c13.255 0 24-10.745 24-24V160h63.966c20.878 0 31.851-24.969 17.587-40.331z"></path>
              </svg>
              <h3 className="text-2xl">Evden Eve Nakliyat</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Asansörlü Eşya Taşıma</h3>
              <p className="text-center">
                Adet Gözetmeden <strong>Asansörlü Eşya Taşıma </strong> Hizmeti
                Veriyoruz. Eşyalarınızı Binanın Dışına Asansör Kurarak
                Dilediğiniz Kata Çıkarıyoruz.
              </p>
              <NavLink
                to={"/evden-eve-tasimacilik"}
                title="evden-eve-taşımacılık"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                width="60px"
                height="200px"
                className="fill-white mx-auto"
                viewBox="0 0 384 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 40c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm121.2 231.8l-143 141.8c-4.7 4.7-12.3 4.6-17-.1l-82.6-83.3c-4.7-4.7-4.6-12.3.1-17L99.1 285c4.7-4.7 12.3-4.6 17 .1l46 46.4 106-105.2c4.7-4.7 12.3-4.6 17 .1l28.2 28.4c4.7 4.8 4.6 12.3-.1 17z"></path>
              </svg>
              <h3 className="text-2xl">Sigortalı Nakliyat</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Sigortalı Nakliyat</h3>
              <p className="text-center">
                Taşınma Sürecinde Eşyalarınızı Tüm Olumsuzluklara Karşı
                Sigortalayarak <strong>Sigortalı Nakliyat</strong> Hizmetini
                Sağlıyoruz.
              </p>
              <NavLink
                to={"/sigortalı-nakliyat"}
                title="sigortalı-nakliyat"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
          <div className="relative bg-blue-600 w-80 md:w-52 h-80 lg:w-1/3 text-white group overflow-hidden">
            <div className="group-hover:blur-md transition-all duration-500">
              <svg
                width="60px"
                height="200px"
                className="fill-white mx-auto"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"></path>
              </svg>
              <h3 className="text-2xl">Komple Bina Nakliyatı</h3>
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-evenly items-center bg-blue-700 text-white p-4 opacity-0 scale-90 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100">
              <h3 className="text-2xl">Komple Bina Nakliyatı</h3>
              <p className="text-center">
                Genellikle Kentsel Dönüşüm Ya Da Farklı Bir Sebeple Binadaki
                Eşyaların Komple Taşınması Gereken Durumlarda
                <strong>Bina Taşıma</strong> Hizmetini Veriyoruz.
              </p>
              <NavLink
                to={"/komple-bina-tasima"}
                title="komple-bina-taşıma"
                className="block bg-yellow-500 w-2/4 mx-auto py-1 rounded-lg text-center"
              >
                İnceleyin
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className=" flex flex-col space-y-5 text-center p-5 ">
        <h3 className="text-3xl font-bold">Asansörlü Eşya Taşıma</h3>
        <p className=" text-sm/7">
          Müşteri talebi veya
          <em>bina içerisinden eşya taşınmasının uygun olmadığı durumlarda</em>
          <strong>asansörlü taşıma</strong> hizmetini vermekteyiz.
          <em>
            <strong>Asansörlü eşya taşıma</strong>
          </em>
          hizmetimiz her türlü ev ve iş yeri için geçerli olup yüksek ya da
          alçak kat farketmemektedir. Asansörlü taşıma hizmetimize ek olarak{" "}
          <strong>vinçle eşya taşıma, evden eve vinç, asansörlü vinç</strong>{" "}
          hizmetlerimizde bulunmaktadır. Özfilo Evden Eve Nakliyat firması
          olarak güvenli bir şekilde
          <strong>asansörlü evden eve nakliyat</strong> işlemlerinizi yapmaktan
          mutluluk duyarız. En uygun fiyatlı hizmet alabilmek için lütfen
          bizimle iletişime geçin.
        </p>
        <NavLink
          to={"/asansorlu-esya-tasima"}
          target="_blank"
          title="Asansörlü eşya taşıma - asansörlü taşıma"
          className="flex justify-evenly w-72 mx-auto px-10 py-1 border-2 text-gray-500 text-xl"
        >
          <svg
            aria-hidden="true"
            width="12px"
            className="fill-gray-500 mx-auto"
            viewBox="0 0 320 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M313.553 119.669L209.587 7.666c-9.485-10.214-25.676-10.229-35.174 0L70.438 119.669C56.232 134.969 67.062 160 88.025 160H152v272H68.024a11.996 11.996 0 0 0-8.485 3.515l-56 56C-4.021 499.074 1.333 512 12.024 512H208c13.255 0 24-10.745 24-24V160h63.966c20.878 0 31.851-24.969 17.587-40.331z"></path>
          </svg>
          <h3 className=" ">Asansörlü Ev Taşıma</h3>
        </NavLink>
      </section>
      <section className=" flex flex-col space-y-5 text-center p-5 lg:p-20">
        <div className=" border border-gray-500 w-full mx-auto"></div>
        <h3 className=" text-3xl font-semibold">TÜM HİZMETLER</h3>
        <div className="flex flex-col md:flex-row text-center space-y-4 text-md">
          <div className="flex flex-col space-y-3">
            <NavLink
              to={"/evden-eve-nakliyat"}
              title="Evden eve nakliyat - nakliyat"
            >
              Evden eve nakliyat
            </NavLink>
            <NavLink
              title="Şehir içi nakliyat - şehir içi nakliyat"
              to={"/sehir-ici-nakliyat"}
            >
              Şehir içi nakliyat
            </NavLink>
            <NavLink
              title="Şehirler arası nakliyat - Şehirler arası nakliyat"
              to={"/sehirler-arasi-nakliyat"}
            >
              Şehirler arası nakliyat
            </NavLink>
            <NavLink
              title="Asansörlü eşya taşıma - asansörlü taşıma"
              to={"/asansorlu-esya-tasima"}
            >
              Asansörlü taşıma
            </NavLink>
            <NavLink
              title="Eşya Depolama - depo kiralama"
              to={"/esya-depolama"}
            >
              Eşya Depolama
            </NavLink>
            <NavLink
              title="Parça eşya taşıma - eşya taşıma"
              to={"/parca-esya-tasima"}
            >
              Parça eşya taşıma
            </NavLink>
          </div>
          <div className=" border md:w-0 border-gray-300 w-2/3 mx-auto"></div>
          <div className="flex flex-col space-y-3">
            <NavLink
              to={"/rezidans-daire-nakliyat"}
              title="Rezidans daire nakliyat - daire nakliyat"
            >
              Rezidans daire nakliyat
            </NavLink>
            <NavLink
              to={"/rezidans-ofis-nakliyat"}
              title="Rezidans ofis nakliyat - ofis nakliyatı"
            >
              Rezidans ofis nakliyat
            </NavLink>
            <NavLink
              to={"/malikane-nakliyat"}
              title="Malikane nakliyat - nakliyat malikane"
            >
              Malikane nakliyat
            </NavLink>
            <NavLink to={"/paketleme"} title="paketleme - paket taşıma">
              Paketleme
            </NavLink>
            <NavLink title="Para kasası nakliyatı - kasa nakliyatı">
              Para kasası nakliyatı
            </NavLink>
            <NavLink
              to={"/referanslarimiz"}
              title="Referanslarımız - nakliyat referansları"
            >
              Referanslarımız
            </NavLink>
          </div>
          <div className=" border md:w-0 border-gray-300 w-2/3 mx-auto"></div>
          <div className="flex flex-col space-y-3">
            <NavLink title="İşyeri taşıma - iş taşıma" to={"/ofis-tasima"}>
              İşyeri taşıma
            </NavLink>
            <NavLink
              title="Komple bina taşıma - nakliyat"
              to={"/komple-bina-tasima"}
            >
              Komple bina taşıma
            </NavLink>
            <NavLink
              title="Sigortalı nakliyat - sigortalı nakliyat"
              to={"/sigortalı-nakliyat"}
            >
              Sigortalı nakliyat
            </NavLink>
            <NavLink
              title="Piyanı nakliyatı - enstrüman nakliyat"
              to={"/piyano-nakliyat"}
            >
              Piyano nakliyatı
            </NavLink>
            <NavLink
              title="Antika eşya nakliyat - antika nakliyat"
              to={"/antika-esya-nakliyat"}
            >
              Antika eşya nakliyatı
            </NavLink>
            <NavLink title="Villa taşıma" to={"/villa-nakliyat"}>
              Villa taşıma
            </NavLink>
            <NavLink title="Yalı nakliyatı" to={"/yali-nakliyat"}>
              Yalı nakliyatı
            </NavLink>
          </div>
          <div className=" border md:hidden border-gray-500 w-full mx-auto"></div>
        </div>
      </section>
      <section className=" bg-blue-600 text-white text-5xl md:text-7xl font-bold flex flex-wrap text-center p-10">
        <div className=" w-1/2 p-10">
          <p>26+</p>
          <p className="text-sm md:text-lg ">YIL TECRÜBE</p>
        </div>
        <div className=" w-1/2 p-10">
          <p>10+</p>
          <p className="text-sm md:text-lg ">ARAÇ SAYISI</p>
        </div>
        <div className=" w-1/2 p-10">
          <p>50+</p>
          <p className="text-sm md:text-lg ">ÇALIŞAN</p>
        </div>
        <div className=" w-1/2 p-10">
          <p>81+</p>
          <p className="text-sm md:text-lg ">İLE HİZMET</p>
        </div>
      </section>
      <section className="  relative">
        <img
          src={memurlar}
          alt="kamu-ve-memurlara-indirim"
          className=" h-[400px] md:w-full "
        />
        <div className=" absolute top-0 left-0 md:p-10 lg:p-20">
          <h3 className=" text-4xl text-center pt-28 md:pt-20 lg:pt-10 lg:text-6xl text-white">
            Kamu ve Devlet memurlarına ekstra{" "}
            <span className="text-yellow-400">10% İNDİRİM</span>
          </h3>
          <div className="flex space-x-3 px-10 mt-20 text-center text-white">
            <NavLink
              className="block py-3 rounded-xl bg-yellow-400 w-1/2 md:text-2xl"
              to={"whatsapp://send?phone=5323508851"}
            >
              Teklif Alın
            </NavLink>
            <NavLink
              className="block py-3 rounded-xl bg-black w-1/2 md:text-2xl"
              to={"tel:053220362200016"}
            >
              Hemen Ulaşın
            </NavLink>
          </div>
        </div>
      </section>
      <section className="px-10 lg:px-5">
        <div className="text-gray-600 text-center mb-6">
          <h3 className="text-3xl ">BLOG YAZILARI</h3>
          <p>Nakliye Hakkında Önemli Bilgiler</p>
        </div>
        <div className="flex flex-col md:flex-row md:flex-wrap  items-center space-y-3  ">
          <NavLink
            to={"/sehir-disi-ev-tasima"}
            title="sehir-dışı-ev-taşıma"
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog3}
              alt="şehir-dışı-ev-taşıma-fiyatları"
              title="şehir dışı ev taşıma fiyatları"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">
              Şehir Dışı Ev Taşıma Fiyatları 2025
            </h2>
            <p className=" text-gray-500 px-3">
              Şehir dışı güncel ev taşıma fiyatları 2025 için de belirlenmiş
              durumdadır. Güncel hizmet fiyatlarımız hakkında bilgi almak
              isteyenler 7/24 firmamızla iletişime geçebilirler. Güler yüzlü ve
              deneyimli ekibimiz her sorunuza en doğru ve güncel şekilde cevap
              veriyor olacaktır. Nakliyat hizmetini doğru adresten alarak,
              hizmetten memnun kalmak isteyenler firmamızı her zaman tercih
              edebilirler.
            </p>
          </NavLink>
          <NavLink
            to={"/sultanbeyli-evden-eve-nakliyat"}
            title="sultanbeyli-evden-eve-nakliyat"
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog9}
              alt="sultan-beyli-evden-eve-nakliyat"
              title="sultan beyli evden eve nakliyat"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">Sultanbeyli Evden Eve Nakliyat</h2>
            <p className=" text-gray-500 px-3">
              İstanbul Şehir içi nakliyat Sultanbeyli hizmetlerimiz devam
              ediyor. Parça eşya taşıma, evden eve nakliyat, işyeri ve fabrika
              taşıma gibi çeşitli hizmetlerimizi sizlere sunmaktan mutluluk
              duyuyoruz. Sultanbeyli şehir içi nakliyat ihtiyaçlarınızda
              firmamıza iletişim sayfamızda yer alan numaralarımızdan
              ulaşabilirsiniz.
            </p>
          </NavLink>
          <NavLink
            to={"/pendik-evden-eve-nakliyat"}
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog5}
              alt="pendik-evden-eve-nakliyat"
              title="pendik evden eve nakliyat"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">Pendik Evden Eve Nakliyat</h2>
            <p className=" text-gray-500 px-3">
              Şehir içi nakliyat firmaları arasında sunduğu hizmet kalitesi ile
              öne çıkan Özfilo evden eve nakliyat ile tüm eşyalarınız güvenle
              taşınır. Pendik şehir içi nakliyat hizmetimiz çerçevesinde Tüm
              Pendik halkına uzman ekip ile en profesyonel hizmeti sunmaya
              hazırız.
            </p>
          </NavLink>
          <NavLink
            to={"/sancaktepe-evden-eve-nakliyat"}
            title="sancaktepe-evden-eve-nakliyat"
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog6}
              alt="sancaktepe-evden-eve-nakliyat"
              title="sancaktepe evden eve nakliyat"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">Sancektepe Evden Eve Nakliyat</h2>
            <p className=" text-gray-500 px-3">
              Şehir içi nakliyat firmaları arasında Sancaktepe bölgesine de
              hizmet veren firmamız tüm eşyalarınızın nakliyat işlemlerini
              güvenle yapmaktadır. Özfilo Evden Eve Nakliyat güvencesi ile
              Sancaktepe şehir içi nakliyat hizmetlerimizden faydalanmak ister
              misiniz? 0532 362 0016 numaralı müşteri hizmetlerimizden bizlere
              ulaşabilirsiniz.
            </p>
          </NavLink>
          <NavLink
            to={"/kartal-evden-eve-nakliyat"}
            title="kartal-evden-eve-nakliyat"
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0 pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog2}
              alt="kartal-evden-eve-nakliyat"
              title="kartal evden eve nakliyat"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">Kartal Evden Eve Nakliyat</h2>
            <p className=" text-gray-500 px-3">
              Özfilo evden eve nakliyat, 26 yıldan uzun süredir şehirler arası
              ve şehir içi nakliyat hizmetleri sunmaktadır. Firmamız İstanbul’un
              her bir semtinde olduğu gibi Kartal şehir içi nakliyat
              ihtiyaçlarına da hızlı, güvenli ve fiyat avantajları ile yanıt
              vermektedir.
            </p>
          </NavLink>
          <NavLink
            to={"/pendik-evden-eve-nakliyat"}
            title="pendik-evden-eve-nakliyat"
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog4}
              alt="pendik-evden-eve-nakliyat"
              title="pendik evden eve nakliyat"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">Pendik Evden Eve Nakliyat</h2>
            <p className=" text-gray-500 px-3">
              Özfilo evden eve nakliyat, 26 yıldan uzun süredir şehirler arası
              ve şehir içi nakliyat hizmetleri sunmaktadır. Firmamız İstanbul’un
              her bir semtinde olduğu gibi Kartal şehir içi nakliyat
              ihtiyaçlarına da hızlı, güvenli ve fiyat avantajları ile yanıt
              vermektedir.
            </p>
          </NavLink>
          <NavLink
            to={"/kartal-evden-eve-nakliyat"}
            title="kartal-evden-eve-nakliyat"
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog1}
              alt="kartal-evden-eve-nakliyat"
              title="kartal evden eve nakliyat"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">Kartal Evden Eve Nakliyat</h2>
            <p className=" text-gray-500 px-3">
              Kartal’da ikamet eden ve gerek şehir içi gerekse de şehirlerarası
              taşınma sürecinde bulunan kişilerin en büyük ihtiyacı Kartal evden
              eve nakliyat firması olmaktadır. Bu arayışlarında en doğru evden
              eve nakliyat firması seçimi yaparken doğru karar verilmesi ve
              Özfilo evden eve nakliyat firmamız gibi kurumsal firmaların tercih
              edilmesi alınacak hizmet kalitesi açısından önemlidir.
            </p>
          </NavLink>
          <NavLink
            to={"/sehir-disi-eve-nakliyat"}
            title="şehır-dışı-evden-eve-nakliyat"
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog7}
              alt="sehir-dısı-evden-eve-nakliyat"
              title="şehir dışı evden eve nakliyat"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">Şehir Dışı Evden Eve Nakliyat</h2>
            <p className=" text-gray-500 px-3">
              Şehir dışı evden eve nakliyat şirketi olmasının yanında şehir içi
              hizmet de veren Özfilo Nakliyat, müşteri ayrımı göz etmeksizin
              herkese aynı kalitede nakliyat hizmeti vermektedir. Uygun fiyat
              teklifleri ile taşınma işlemini hızlı bir şekilde gerçekleştirmek
              isteyenler nakliyat firmamızla iletişime geçebilirler. Taşınma
              işleminden maksimum başarı elde etmek isteyenler için firmamız en
              doğru seçenek olacaktır.
            </p>
          </NavLink>
          <NavLink
            to={"/sehir-disi-eve-nakliyat"}
            title="sehir-dışı-ev-taşıma-firması"
            className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
          >
            <img
              src={blog8}
              alt="sehir-dısı-ev-tasıma-firması"
              title="şehir dışı ev taşıma firması"
              className=" rounded-t-md"
            />
            <h2 className=" text-3xl px-3">Şehir Dışı Ev Taşıma Firması</h2>
            <p className=" text-gray-500 px-3">
              Şehir dışı ev taşıma firması olmakla birlikte aynı zamanda
              şehirler arası nakliyat hizmetini farklı şekilde sunan
              ozfilonakliyat.com geçmişte olduğu gibi bugünde müşteride
              oluşturduğu güven duygusu ile en çok tercih edilen firmalardan
              biri olmaktadır. Müşterilerden gelen olumlu geri dönüşler firmamız
              için ilham kaynağı olmakta ve firmamızın daha da gelişmesi için
              motive edici olmaktadır. İyib ir nakliyat firması arayanlar
              firmamızı her zaman arayabilirler.
            </p>
          </NavLink>
        </div>
      </section>
      <section className="flex items-center gap-3  text-white text-md w-full  justify-evenly sticky md:relative bottom-0 h-14">
        <NavLink
          to={
            "https://wa.me/905323508851?text=Merhaba%20Nakliye%20Hakk%C4%B1nda%20Bilgi%20Almak%20%C4%B0stiyorum"
          }
          title="ÖzFilo - Whatsapp "
          className="flex items-center text-center bg-[#25D366] rounded-lg px-2 py-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50px"
            height="50px"
            viewBox="0 0 48 48"
          >
            <path
              fill="#fff"
              d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
            ></path>
            <path
              fill="#fff"
              d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
            ></path>
            <path
              fill="#cfd8dc"
              d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
            ></path>
            <path
              fill="#40c351"
              d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
            ></path>
            <path
              fill="#fff"
              fill-rule="evenodd"
              d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <p>
            Online Destek <br /> WhatsApp
          </p>
        </NavLink>
        <NavLink
          to={"tel:05323620016"}
          title="özfilo - iletişim"
          className="flex items-center text-center bg-black rounded-lg px-2 py-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            width="50px"
            height="50px"
            className=" fill-white"
          >
            <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
          </svg>
          <p>
            Hemen Ulaşın <br /> 0532 362 00 10
          </p>
        </NavLink>
      </section>
    </div>
  );
}

export default Home;
