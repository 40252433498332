import React from "react";
import img1 from "../../img/Blog/sehir-disi-evden-eve-nakliyat-sirketi.jpg";
import { NavLink } from "react-router-dom";
function Sehirİci() {
  return (
    <div>
      <h1 className="text-5xl text-center">ÖzFilo - Şehir İçi Nakliyat</h1>
      <img src={img1} alt="ÖzFilo - Şehir İçi Nakliyat" className="mx-auto" />
      <div className="text-gray-600 p-5 flex flex-col space-y-10 my-10">
        <div className="flex flex-col space-y-5">
          <p>
            Firmamız, sektöründe en fazla tercih edilen ve müşteri memnuniyeti
            konusunda adını duyurmuş bir firmadır. Özfilo Nakliyat olarak 1995
            yılından itibaren hızlı bir şekilde kaliteli hizmetler vermeye ve
            kendimizi geliştirmeye devam etmekteyiz.{" "}
            <strong>Şehir içi nakliyat</strong> hizmetimizi tamamen müşteri
            taleplerine öncelik vererek, profesyonel bir şekilde yürütmekteyiz.
          </p>
          <p>
            Rakip firmaların aksine, her bütçeye <strong>uygun nakliye</strong>{" "}
            hizmeti sunmaktayız. Bununla beraber nakliyat sırasında son
            teknoloji ekipmanlar ve kaliteli ambalaj malzemeleri kullanmaktayız.
            Eşyalarınızın sevki esnasında herhangi bir sorunla karşılaşılmaması
            için titizlikle çalışmalarımızı yapmaktayız. Olası risk durumunda
            ise eşya nakliyat sigortası hizmetimiz ile eşyalarınızı güvence
            altına alabilirsiniz.
          </p>
          <p>
            Firmamız 7/24 hizmet vermektedir. Belirttiğiniz saatte ekibimiz
            evinize gelmekte ve öncelikli olarak eşyalarınızın analizini
            yapmaktadır. <strong>Şehir içi nakliyat</strong> hizmetimiz,
            alanında uzman ve deneyimli personeller tarafından yapılmaktadır.
            Deneyimli personellerimiz eşyalarınıza uygun olan ambalaj
            malzemesiyle, ayrı ayrı paketleme işlemi yapmaktadır. Bu sayede
            nakliyat öncesinde hiçbir şekilde eşya paketleme işlemi yapmanıza
            gerek kalmamaktadır.
          </p>
          <p>
            <strong>Evden eve nakliyat</strong> süresince her bir işlem özenle
            ve titizlikle yapılmaktadır. Aynı gün içerisinde hızlı bir şekilde
            taşınma işleminiz gerçekleştirilmektedir. Gerek paketleme gerekse
            taşıma süresi hızlı bir şekilde yapılarak, aynı gün taşınmanız
            sağlanmaktadır. Tecrübeli personellerimiz sayesinde her bir işlem
            profesyonel bir şekilde yapılmaktadır.
          </p>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">
            Şehir İçi Nakliyat Fiyatları
          </h2>
          <div className="flex flex-col space-y-5">
            <p>
              Gelişen teknolojiyle beraber, ekipmanlarımızı sürekli olarak
              yenilemekte ve son teknoloji ekipmanlar ile hizmet vermekteyiz.
              Pek çok nakliye firmasından ayrıcalıklı olarak,{" "}
              <strong>şehir içi nakliye fiyatları</strong> konusunda oldukça
              uygun fiyat seçenekleri sunmaktayız. Fiyatlandırma politikamız,
              her bütçeye uygun olacak şekilde ayarlanmaktadır.
            </p>
            <p>
              <strong>Şehir içi nakliyat</strong> hizmetimizi İstanbul ve Ankara
              için her semt ve ilçeyi kapsayacak şekilde vermekteyiz. Bu hususta
              günden güne araç filomuzu genişleterek ve hizmet kapasitemizi
              arttırmayı hedeflemekteyiz.
            </p>
            <p>
              Her cebe hitap edecek fiyatlandırma ile çok daha fazla insana
              kaliteli hizmetler sunmayı amaçlamaktayız. Özfilo Nakliyat olarak,
              taşınırken gönül rahatlığıyla tercih edebileceğiniz ve uygun
              fiyatlar ödeyerek profesyonel hizmet alabileceğiniz bir nakliye
              firmasıyız.
            </p>
            <p>
              Sektöründe en fazla tercih edilen nakliye firması olarak,
              vizyonumuz daima uygun fiyata kaliteli hizmet sunmak olmuştur.{" "}
              <strong>Şehir içi nakliyat</strong> hizmetimizi deneyimli
              ekibimiz, kaliteli ambalaj malzemelerimiz ve hızlı taşımacılık
              ilkelerimiz doğrultusunda sunmaktayız. Eşyalarınızı en hızlı
              şekilde taşıyarak, güvenli ve faydalı hizmetler vermekteyiz.
            </p>
            <p>
              Müşteri memnuniyetini esas alarak günden güne hizmet alanımızı
              genişletmeye devam etmekteyiz. Taşımacılık hizmeti sonrasında
              olumlu geri dönüşler ve çevre tavsiyeleri sayesinde, hızlı bir
              şekilde büyümeye devam etmekteyiz. Bu açıdan
              <strong>şehir içi nakliyat</strong> ihtiyaçları için en fazla
              tercih edilen firmalardan biri olmanın gururunu yaşamaktayız.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">
            Şehir İçi Nakliyat İstanbul
          </h2>
          <div className="flex flex-col space-y-5">
            <p>
              <strong>Şehir içi nakliyat İstanbul</strong> ilçe ve semtleri için
              avantajlı hizmetler vermekteyiz. Şehir içi nakliye hizmetlerimiz
              ihtiyaca göre çeşitlilik göstermektedir. Özfilo Nakliyat firmamız,
              pek çok taşımacılık hizmeti sunmaktadır. Taşımacılık hizmetlerimiz
              için farklı fiyatlandırma ve özel hizmet seçenekleri sunmaktayız.
              Talep ve ihtiyacınıza göre tercih edebileceğiniz hizmetlerimiz
              şunlardır:
            </p>
            <ul>
              <li>
                <em>
                  <strong>Asansörlü taşıma</strong>
                </em>
              </li>
              <li>
                <em>
                  <strong>Parça eşya taşıma</strong>
                </em>
              </li>
              <li>
                <em>
                  <strong>Komple bina taşıma</strong>
                </em>
              </li>
              <li>
                <em>
                  <strong>Ofis ve iş yeri taşıma</strong>
                </em>
              </li>
              <li>
                <em>
                  <strong>Villa ve rezidans taşıma</strong>
                </em>
              </li>
              <li>
                <em>
                  <strong>Evden eve nakliyat</strong>
                </em>
              </li>
              <li>
                <em>
                  <strong>Eşya depolama</strong>
                </em>
              </li>
              <li>
                <em>
                  <strong>Evden Eve</strong>
                </em>
                <em>
                  <strong> Nakliyat</strong>
                </em>
              </li>
            </ul>
            <p>
              Her türlü <strong>şehir içi nakliyat</strong> ihtiyaçlarınız için
              firmamız ile iletişime geçebilirsiniz. İstanbul içi tüm semtlere
              ve ilçelere hizmet vermekteyiz.{" "}
              <a href="https://ozfilonakliyat.com/">
                https://ozfilonakliyat.com/
              </a>{" "}
              adresine tıklayarak, sitemiz üzerinden Whatsapp iletişim butonuna
              erişebilirsiniz. Ayrıca dilerseniz adresimize gelerek, dilerseniz
              de web sitemizde bulunan iletişim bilgilerimizden bizi arayarak
              detaylı bilgiye ulaşabilirsiniz.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">
            İstanbul Şehir İçi Evden Eve Nakliyat Firması
          </h2>
          <div className="flex flex-col space-y-5">
            <p>
              Özfilo Nakliyat,{" "}
              <strong>İstanbul şehir içi evden eve nakliyat firması</strong>{" "}
              olarak, her türlü taşınma işleriniz için kaliteli hizmetler
              sunmaktayız. 26 yıllık tecrübemiz ile en profesyonel hizmeti
              vermek için tüm yenilikleri yakından takip etmekteyiz. Evden eve
              nakliyat ihtiyacınız için deneyimli personellerimiz, en hızlı ve
              organize şekilde evinizin taşınmasını sağlamaktadır.
            </p>
            <p>
              Evden eve nakliyat esnasına oluşabilecek tüm riskleri en aza
              indirmek için özenle çalışmaktayız. Bu hususta aşamalı olarak, her
              bir eşyanın cinsi analiz edilmektedir. Analiz sonrasında
              eşyalarınıza uygun olan kaliteli ve temiz ambalajlar seçilerek
              paketleme işlemi yapılmaktadır.
            </p>
            <p>
              Eşyalarınızın maddi ve manevi değeri konusunda en az sizin kadar
              hassasiyet göstermekteyiz. Bu nedenle özellikle kırılabilecek
              eşyalarınız için muntazam şekilde ambalajlama yapılarak paketlere
              konulmaktadır. Tüm eşyaların paketleme işlemi profesyonel bir
              şekilde gerçekleştirildikten sonra, taşıma işlemi için hazır hale
              gelmektedir.
            </p>
            <p>
              <strong>Şehir içi nakliyat</strong> için eşyalarınızın
              araçlarımıza taşıma işlemi sırasında oluşabilecek çarpma, düşme
              gibi durumlarında risk en aza indirilmiş olmaktadır. Bununla
              beraber yine nakliye sigortası hizmetimizden yararlandığınız
              takdirde, olası hasarlara karşılık olarak her türlü zararınız
              karşılanmaktadır.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">
            Şehir İçi Nakliyat Ankara
          </h2>
          <div className="flex flex-col space-y-5">
            <p>
              Özfilo Nakliyat firmamız, <strong>şehir içi nakliyat</strong>{" "}
              hizmetinizi Ankara içi tüm semt ve ilçeler için vermektedir. Ev ve
              ofisinizi taşımak istiyorsanız, firmamızın güvenilir ve kaliteli
              hizmetlerinden faydalanabilirsiniz. Yüksek binalar için asansörlü
              taşımacılık hizmetimizden yararlanabilir, bununla beraber özel
              avantajlar elde edebilirsiniz.
            </p>
            <p>
              <strong>Ankara şehir içi evden eve nakliyat ücretleri</strong>,
              müşteri memnuniyeti açısından en uygun şekilde sunulmaktadır.
              Ankara içi eşya taşımacılık hizmetlerimiz ile ilgili detaylı bilgi
              almak için dilerseniz firmamızı ziyaret edebilir, dilerseniz
              iletişim numaralarımızdan bizimle irtibata geçebilirsiniz. 7/24
              hizmet veren firmamızın web sitesinde bulunan Whatsapp butonu ile
              kolay bir şekilde iletişime geçebilir ve bilgi alabilirsiniz.
            </p>
            <p>
              Geniş araç filomuz ile Ankara içi tüm ilçelere hızlı bir şeklide
              hizmet vermekteyiz. Belirttiğiniz saatte evinize gelen ekibimiz,
              gerekli tüm işlemleri profesyonel bir şekilde tamamladıktan sonra,
              aynı gün içerisinde taşınma işleminizi gerçekleştirmektedir.
            </p>
            <p>
              Firmamız, <strong>şehir içi nakliyat</strong> hizmeti konusunda
              sektöründe en başarılı hizmetleri sunmaktadır. Kaliteli paketleme
              işlemi, hızlı ve güvenli eşya sevki hizmetleri sunan firmamızı
              gönül rahatlığı ile tercih edebilirsiniz. Taşınan eşyalarınızın,
              yeni eviniz veya iş yerinize ulaşmasının ardından, hızlı bir
              şekilde ambalajlar açılarak eşyalarınız yerleştirilmektedir. Bu
              sayede hiç yorulmadan taşınabilir ve kaliteli hizmetlerin tadını
              çıkarabilirsiniz.
            </p>
            <p>
              <strong>Ankara şehir içi nakliye fiyatları</strong>, firmamız
              tarafından minimum düzeyde ve uygun olarak ayarlanmaktadır.
              “Maksimum hizmet minimum ücret” politikamız ile hizmet alanımızı
              genişletmeye devam etmekteyiz. Ankara içi taşınma işlemleriniz
              için firmamızı tercih ederek, kaliteli ve uygun fiyatlı hizmet
              seçeneklerimizden yararlanabilirsiniz.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">Şehir İçi Eşya Taşıma</h2>
          <div className="flex flex-col space-y-5">
            <p>
              Taşınma işlemi pek çok kişinin tedirgin olduğu bir konudur. Ancak
              doğru firmanın tercih edilmesiyle bu tedirginlik durumu ortadan
              kalkacaktır. Firmamız, <strong>şehir içi eşya taşıma </strong>
              konusunda yıllardır edindiğimiz tecrübelerin ışığında, profesyonel
              bir deneyim yaşatmaktadır.
            </p>
            <p>
              Bilindiği gibi eşya taşıma işlemi büyük dikkat gerektiren bir
              iştir. Özfilo Nakliyat bünyesinde çalışan uzman ekibimiz, siz
              değerli müşterilerimizin hassasiyetini dikkate alarak kaliteli
              işler ortaya çıkarmaktadır. Bu sebepledir ki{" "}
              <strong>şehir içi nakliyat</strong> alanında güvenilir ve tercih
              edilen bir firma olarak adını duyurmuştur.
            </p>
            <p>
              Taşınma işlemi herkesin sıklıkla karşılaştığı bir durum olmadığı
              için panik olunması çok normaldir. Ancak güvenilir ve profesyonel
              hizmetler sunan bir firmayla anlaşmak, içinizin rahatlamasını
              sağlayacak en önemli husustur. Nakliyat sektöründe eşya taşıma
              sürecini dikkatle ve titizlikle sürdüren bir firmayız.
            </p>
            <p>
              İstanbul ve Ankara içi eşya taşıma hizmetleri sunmaktayız.
              Parsiyel taşımacılık adıyla bilinen{" "}
              <strong>parça eşya taşıma</strong> konusunda profesyonel hizmetler
              vermekteyiz. Bu hizmet öncesinde kaç adet eşya taşınacağının
              belirlenmesinin ardından, uygun maliyetli bir taşımacılık hizmeti
              vermekteyiz. Eşyalarınızın büyüklüğü veya adedi fark etmeksizin
              tüm eşyalarınızı kaliteli bir paketleme işleminin ardından güvenle
              taşımaktayız.
            </p>
            <p>
              Firmamızla yüz yüze, telefonla veya Whatsapp üzerinden
              mesajlaşarak iletişime geçerek detaylı bilgi alabilirsiniz.{" "}
              <strong>Şehir içi nakliyat</strong> konusunda aklınıza takılan her
              soruyu öğrenebileceğiniz, 7/24 iletişim destek hizmeti veren bir
              firmayız. Hizmet sürecinin işleyiş biçiminden fiyatlandırma
              politikamıza kadar almak istediğiniz her türlü bilgiyi
              öğrenebilirsiniz. Hizmet almak istediğinizde ise taşınma
              işleminizi aynı gün içinde hızlı bir şekilde yapmaktayız.
            </p>{" "}
            >
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">Şehir İçi Taşımacılık</h2>
          <div className="flex flex-col space-y-5">
            <p>
              <strong>Şehir iç taşımacılık </strong>alanında yıllardır hizmet
              veren firmamız, deneyimli ekip ve son teknoloji ekipmanlar ile
              profesyonel bir şekilde çalışmaktadır. Taşınma süresince
              sağladığımız apek çok avantajdan yararlanabilirsiniz. Hiçbir
              şekilde yorulmadan ve yalnızca taleplerinizi ileterek gönül
              rahatlığıyla taşınmış olacaksınız. Firmamız paketleme ve
              taşımacıık hizmetinin yanı sıra, yeni evinize veya işyerinize
              getirdiğimiz eşyalarınızın yerleştirme işlemini de yapmaktadır.
            </p>
            <p>
              Kendi başınıza yapmak zorunda kalmayacağınız paketleme ve taşınma
              işlemini kaliteli bir şekilde yapmaktayız. Gerek paketleme
              esnasında kullandığımız ambalajlar için kaliteli ve temiz
              malzemeler kullanılması gerekse hızlı bir şekilde sevkinin
              gerçekleşmesi konusunda başarılı bir firmayız.{" "}
              <strong>Şehir içi nakliyat</strong> konusunda hiçbir işe elinizi
              değmeniz gerekmeden, vereceğiniz direktiflerle kaliteli hizmetler
              alabilirsiniz.
            </p>
            <p>
              Taşımacılık esnasında kullanmakta olduğumuz araçlarımız son kalite
              taşımacılık araçlarıdır. Büyük eşyalarınız için ideal olarak
              tasarlanmış geniş kasalı araçlarımız mevcuttur. Parça eşya taşıma
              işlemleriniz için ise yine eşya taşımacılık için kusursuz bir
              şekilde tasarlanmış olan araçlarla hizmet vermekteyiz. Araç
              şoförlerimizden taşıma ve paketleme işlemi yapan tüm personelimiz,
              alanında uzman ve deneyimli bir ekiptir.
            </p>
            <p>
              Eşyalarınızın risk durumu için sigortalı ve sözleşmeli taşımacılık
              avantajlarımızdan yararlanabilirsiniz. Oluşabilecek olası bir
              hasar durumunda zararınız sorgusuz bir şekilde karşılanmaktadır.
            </p>
            <p>
              Firmamızın güvenilirliği ve müşteri memnuniyeti hızlı bir şekilde
              büyümemize büyük katkı sağlamıştır.{" "}
              <strong>Şehir içi nakliyat</strong> dendiğinde akıllara ilk gelen
              firmalardan olmamız, yıllardır edindiğimiz tecrübemiz ve
              dolayısıyla da başarımızın göstergesidir.
            </p>{" "}
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">
            Uygun Fiyatlı Şehir İçi Nakliyat
          </h2>
          <div className="flex flex-col space-y-5">
            <p>
              26 yıllık deneyim ile değişim ve gelişime açık firmamız Özfilo
              Nakliyat, nakliye alanında teknolojiyi yakından takip etmektedir.
              Arz ve talep ilişkisi içinde müşterilerine en iyi hizmeti
              sunabilmek adına her türlü gelişmeden haber olmaktayız.
              Hizmetlerimizi sağlam ekipmanlar ile sunmakta kusursuz araçlarla
              taşımacılık işlemini gerçekleştirmekteyiz.
            </p>
            <p>
              <strong>Uygun fiyatlı şehir içi nakliyat </strong>hizmetimizi en
              kaliteli şekilde siz değerli müşterilerimize sunmaktayız. Güler
              yüzlü ekibimiz ve kusursuz nakliye araçlarımız ile daime en iyi
              hizmetleri verebilmek için çalışmaktayız. Ayrıca kamu çalışanları
              ve devlet memurları için ekstra %10 indiriminden
              faydalanabilirsiniz.
            </p>
            <p>
              Dilerseniz web sitemiz üzerinden fiyat teklifi al butonuna
              tıklayarak uygun fiyatlı hizmetlerimiz hakkında bilgi sahibi
              olabilirsiniz. Aynı şekilde ana sayfamızın alt kısmında bulunan
              iletişim bilgilerimizden veya telefon et butonundan bizimle
              iletişime geçebilirsiniz. Firmamızın tüm hizmetleri için her cebe
              uygun fiyatlandırma seçenekleri bulunmaktadır.
            </p>
            <p>
              Kaliteli hizmet kapsamında uygun fiyatlı{" "}
              <strong>şehir içi nakliyat</strong> hizmetlerinden
              yararlanabilirsiniz. Hizmetlerimiz Türkiye’nin tüm illerini
              kapsamaktadır. Yüksek binalar için kullandığımız asansörlü eşya
              taşıma hizmetimiz de bulunmaktadır.
            </p>
            <p>
              Şehirlerarası ve <strong>şehir içi nakliyat</strong>{" "}
              hizmetlerimizi, komple bina taşıma, evden eve ve işyeri nakliyatı,
              parça eşya taşıma ve depolama gibi pek çok alanda sunmaktayız.
              Uygun fiyatlı nakliye firması arayışındaysanız, Özfilo Nakliyat
              olarak tam da aradığınız firmayız. Uygun fiyat karşılığında
              profesyonel bir hizmet almak için firmamızla iletişime geçebilir,
              eşyalarınızı güvenle bizlere emanet edebilirsiniz.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ">
          <NavLink
            to={"tel:05323620016"}
            title="özfilo - iletişim"
            className="flex items-center lg:w-2/4 xl:w-1/4 mx-auto my-5 justify-center group hover:text-blue-700 hover:border-blue-700 hover:border-2 gap-3 p-3 border border-gray-600 text-gray-600 text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-gray-600 group-hover:fill-blue-700"
              width="30px"
              height="30px"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
            </svg>
            0532 362 0016
          </NavLink>
          <NavLink
            to={
              "https://wa.me/905323508851?text=Merhaba%20Nakliye%20Hakk%C4%B1nda%20Bilgi%20Almak%20%C4%B0stiyorum"
            }
            title="özfilo - iletişim"
            className="flex my-5 bg-[#25D366] lg:w-2/4 xl:w-1/4 mx-auto items-center justify-center group  hover:border-black hover:border-2 gap-3 p-3 border border-black text-white text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-white"
              width="30px"
              height="30px"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
            </svg>
            0532 350 88 51
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Sehirİci;
