import React from "react";
import img1 from "../../img/Hizmetler/sancaktepe-sehir-ici-nakliyat-768x480.jpg";
import img2 from "../../img/Hizmetler/anadolu-yakasi-evden-eve-nakliyat.jpg";
import img3 from "../../img/Blog/sehir-disi-ev-tasima-firmasi.jpg";
import img4 from "../../img/Blog/sultanbeyli-sehir-ici-nakliyat-768x480.jpg";
import img5 from "../../img/Hizmetler/asansorlu-nakliyat-istanbul.jpeg";
import { NavLink } from "react-router-dom";
function EvdenEve() {
  return (
    <div>
      <h1 className="text-5xl text-center">ÖzFilo - Evden Eve Nakliyat</h1>
      <img src={img1} className="mx-auto" alt="Özfilo - Evden Eve Nakliyat" />
      <div className="p-5">
        <div className=" flex flex-col lg:text-xl space-y-10 my-10 text-gray-600">
          <p>
            <strong>Özfilo Evden Eve Nakliyat</strong> olarak; 26 yıllık
            tecrübemizle tüm Türkiye genelinde{" "}
            <strong>evden eve nakliyat</strong> hizmeti sunmaktayız.
            Kurulduğumuz günden bugüne evden eve nakliyat konusunda her zaman
            iddialı bir firma olduk. Gelişen teknolojiyi takip ederek
            sistemimize entegre ettik. Müşterilerimizin taleplerini ve geri
            dönüşlerini her zaman dikkate alarak en kaliteli ve güvenilir
            hizmeti vermek için çabaladık. Siz değerli müşterilerimizin
            katkılarıyla her zaman büyüdük ve hizmet kalitemizi genişleterek
            daha çok başarılar elde ettik. Bugün her türlü talebi
            karşılayabilecek araç filomuz, uzman ve özverili ekip arkadaşlarımız
            ve her ihtiyaç için yeterli ekipmanımız bulunmaktadır.{" "}
            <strong>Şehir içi evden eve nakliyat</strong> ya da{" "}
            <strong>şehirler arası evden eve nakliyat</strong> konusunda bilgi,
            fiyat veya hizmet almak için bize ulaşabilirsiniz.
          </p>
          <p>
            <strong>Evden eve nakliyat</strong> ihtiyaçlarınız için profesyonel
            bir firma tercih etmek çok önemlidir. Taşınmak gibi sıkıntılı ve
            stresli bir süreci sorunsuz bir şekilde atlatabilmek adına, firma
            kalitesine dikkat edilmelidir. Bu anlamda Özfilo Evden Eve Nakliyat
            olarak, müşterilerimize kusursuz bir taşınma deneyimi yaşatmaktayız.
            26 yıllık deneyimin yanı sıra her zaman müşteri memnuniyeti ilkesi
            doğrultusunda hareket etmekteyiz. Firmamızın sektöründe lider bir
            konumda olmasının temel nedeni tamamen olumlu geri dönüşlerdir.
          </p>
          <p>
            Yıllardır hizmet verdiğimiz nakliyat sektöründe, en fazla tercih
            edilen firmalardan biri olmanın gururunu yaşamaktayız. Müşteri
            memnuniyeti esas alınarak oluşturduğumuz hizmet politikamız{" "}
            <strong>evden eve nakliyat</strong> ihtiyaçlarında ilk akıllara
            gelen firma olmamızın nedenidir. Eşyalarınıza duyduğunuz
            hassasiyetin farkındayız. Aynı hassasiyetle çalışmalarımızı
            yürütmekte ve büyük bir titizlikle çalışmaktayız. Nakliyat alanında
            birçok avantajlı hizmet seçeneklerimiz bulunmaktadır.
          </p>
          <p>
            Taşınma gibi önemli bir konuda, bilinçsizce yapılacak her hareket
            büyük zararlara neden olabilmektedir. Bu nedenle taşınma
            işlemleriniz için tercih edeceğiniz firmanın deneyimli olması büyük
            önem taşımaktadır. Kurulduğu ilk günden bu yana firmamız bünyesinde
            çalışan personellerimiz, alanında uzman ve deneyimli personellerdir.
          </p>
          <p>
            Özfilo Evden Eve Nakliyat firmamızın en önemli ilkelerinden biri de
            profesyonel ekip ve profesyonel ekipmandır. Alanında uzman işini
            hakkıyla yapan güler yüzlü personellerimiz ile muhteşem bir taşınma
            deneyimi yaşayacağınızdan hiç şüpheniz olmasın.{" "}
            <strong>Evden eve nakliyat</strong> konusunda 26 yıldır sektöründe
            tanınan ve eşya taşıma hizmetlerini en kaliteli şekilde sunan bir
            firmayız.
          </p>
        </div>
        <div className="flex flex-col md:flex-row ">
          <NavLink
            to={"tel:05323620016"}
            title="özfilo - iletişim"
            className="flex items-center lg:w-2/4 xl:w-1/4 mx-auto my-5 justify-center group hover:text-blue-700 hover:border-blue-700 hover:border-2 gap-3 p-3 border border-gray-600 text-gray-600 text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-gray-600 group-hover:fill-blue-700"
              width="30px"
              height="30px"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
            </svg>
            0532 362 0016
          </NavLink>
          <NavLink
            to={
              "https://wa.me/905323508851?text=Merhaba%20Nakliye%20Hakk%C4%B1nda%20Bilgi%20Almak%20%C4%B0stiyorum"
            }
            title="özfilo - iletişim"
            className="flex my-5 bg-[#25D366] lg:w-2/4 xl:w-1/4 mx-auto items-center justify-center group  hover:border-black hover:border-2 gap-3 p-3 border border-black text-white text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-white"
              width="30px"
              height="30px"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
            </svg>
            0532 350 88 51
          </NavLink>
        </div>
        <div>
          <h2 className="mx-auto text-3xl lg:text-6xl mt-10 mb-3">
            Evden Eve Nakliyat Firması
          </h2>
          <img
            src={img2}
            className="mx-auto"
            alt="Özfilo - Evden Eve Nakliyat Firması"
          />
          <div className=" flex flex-col lg:text-xl space-y-10 my-10 text-gray-600">
            <p>
              <strong>Evden eve nakliyat firması</strong> tercih ederken dikkat
              etmeniz gereken pek çok husus vardır. Yukarıda belirttiğimiz gibi
              öncelik olarak deneyim ve firmanın tecrübeli bir ekiple
              çalışmasıdır. Bunun yanı sıra, bizim de kendimize ilke olarak
              edindiğimiz bir başka önemli husus da oluşabilecek her türlü risk
              durumuna karşın önlemler alınmasıdır. Özfilo Evden Eve Nakliyat,
              eşyalarınızın taşınma süresi boyunca uğrayabileceği her türlü kaza
              ve hasar durumuna karşın eşya nakliye sigortası hizmeti
              sunmaktadır.
            </p>
            <p>
              Sigorta kapsamında, taşınma işlemleri süresince vurma, kırma,
              düşürme ya da sevk esnasında olası trafik kazalarına karşın tüm
              zararınızı karşılamaktadır. Ayrıca bu gibi risk durumları için
              gerekli tüm önlemlerin büyük bir sorumlulukların alındığını
              belirtmek isteriz. Doğru bir paketleme işlemi ve taşıma sırasında
              dikkatli tutum sergilememiz, risk oluşumunu en aza indirmektedir.
              Ancak başka faktörler neticesinde kaza ve hasar durumları oluşması
              ihtimaline karşın, eşya nakliyat sigortası bir güvencedir.
            </p>
            <p>
              Yıllardır hizmet verdiğimiz nakliye sektöründe,{" "}
              <strong>evden eve nakliyat</strong> hizmeti sonrasında
              müşterilerimizin olumlu geri dönüşler yapması bizleri motive eden
              yegane unsurdur. Firmamızı tercih etmenin sonucunda kusursuz bir
              taşınma süreci yaşamanızı garanti ediyoruz. Özfilo Evden Eve
              Nakliyat olarak, hizmet alanımızı günden güne genişletmekte ve en
              kaliteli hizmetleri sunmaya devam etmekteyiz.
            </p>
          </div>
        </div>
        <div>
          <h2 className="mx-auto text-3xl lg:text-6xl mt-10 mb-3">
            Şehirler Arası Evden Eve Nakliyat
          </h2>
          <img
            src={img3}
            className="mx-auto"
            alt="Özfilo - Şehirler Arası Evden Eve Nakliyat"
          />
          <div className=" flex flex-col lg:text-xl space-y-10 text-gray-600 my-10">
            <p>
              <strong>Şehirlerarası evden eve nakliyat</strong> hizmetimizde
              esas aldığımız konu güvenli taşımacılık ve hızlı sevk işlemidir.
              Özellikle uzun yollarda uzun mesafelerde eşya taşıma esnasında
              trafikte çok fazla zaman kaybedilebilir. Bu nedenle
              müşterilerimize herhangi bir mağduriyet yaşatmamak adına hızlı
              taşımacılık hizmeti bizim için önemlidir.
            </p>
            <p>
              Bunun yanı sıra eşyalarınızın taşınması esnasında doğru paketleme
              işlemleri ve profesyonel ekibimiz sayesinde olası bir kaza durumu
              söz konusu değildir. Bu gibi durumlarda sigorta hizmetimizin
              avantajlarından yararlanarak güvencenizi ikiye katlayabilirsiniz.
            </p>
            <p>
              Şehirlerarası eşya taşımacılığı her insan için stresli bir
              süreçtir. Eşyaların uzun mesafe yol boyunca başına bir şey gelip
              gelmeme durumu müşterilerin huzursuz olmasına neden olmaktadır.
              Ancak <strong>şehirlerarası evden eve nakliyat</strong> konusunda
              yıllardır edindiğimiz tecrübelerimiz ile programınızın hiçbir
              şekilde aksamayacağının garantisini veriyoruz. Uzun yola gidecek
              olan nakliye araçlarımızın bakımının sürekli olarak yapılmasına
              dikkat etmekteyiz.
            </p>
            <p>
              Seyahat öncesi araçlarımız için yapmış olduğumuz bakımlar
              neticesinde, sevk esnasında oluşacak riskleri minimum seviyede
              tutmaktayız. Özellikle kış mevsiminde taşınacak olan
              müşterilerimiz için, araçlarımızda her türlü bakımın yanı sıra
              yedek lastik, zincir, takoz vs. gibi gerekli donanımlarını
              bulundurmaktayız.
            </p>
            <p>
              Özfilo Evden Eve Nakliyat firmamız, rakip firmaların aksine araç
              bakımına fazlasıyla önem vermekte ve taşınma işlemini geciktirecek
              olasılıklara karşı önlemleri maksimum düzeyde tutmaktadır.
              Şehirlerarası taşımacılık hizmetimizi verirken olası risk
              önlemlerinin yanı sıra yasal tedbirlerimizi de almaktayız. Bu
              sayede şehirlerarası taşınma işlemleri için güvenli bir deneyim
              yaşatmaktayız.
            </p>
          </div>
        </div>
        <div>
          <h2 className="mx-auto text-3xl lg:text-6xl mt-10 mb-3">
            Şehir İçi Evden Eve Nakliyat
          </h2>
          <img
            src={img4}
            className="mx-auto"
            alt="Özfilo - Şehir dışı ev taşıma firması"
          />
          <div className=" flex flex-col lg:text-xl text-gray-600 space-y-10 my-10">
            <p>
              <strong>Şehir içi evden eve nakliyat</strong> hizmetini sunarken
              profesyonel ve kaliteli çalışmalar yürütmekteyiz. Sistematik bir
              şekilde planladığımız taşınma sürecini kusursuz
              gerçekleştirmekteyiz. Bu sayede siz değerli müşterilerimizin
              hesaplaması gereken en küçük bir ayrıntıyla bile
              uğraştırmamaktayız.
            </p>
            <p>
              Sorunsuz ve güvenilir hizmetler sunmakta ve yalnızca sizin
              talepleriniz doğrultusunda istediğiniz şekilde hareket etmekteyiz.
              Gerek paketleme gerekse eşyaları araca yükleme esnasında,
              planımıza sadık fakat sizin yönlendirmelerinizi dikkate alarak
              çalışmaktayız. Eşyalarınızı paketleme işlemi sırasında, piyasadaki
              en kaliteli ambalaj malzemelerini tercih etmekteyiz. Eşyaların
              cinsine göre analizler yaptıktan sonra güvenli bir paketleme
              işlemi yapmaktayız.
            </p>
            <p>
              Paketleme işlemi sonrası <strong>evden eve nakliye</strong>{" "}
              hizmetimizi profesyonel bir şekilde gerçekleştirmekteyiz. Firmamız
              bünyesinde çalışan ekip arkadaşlarımız, alanında eğitim almış ve
              deneyimli personellerden oluşmaktadır. Ayrıca gerek duyduğumuz
              alanlarda kullanmak üzere bulundurduğumuz ekipmanlarımız son
              teknoloji ekipmanlardır. Bu noktada gelişen teknolojiyi yakından
              takip etmekte ve hizmet kalitemizi arttırmak amacıyla son
              teknoloji araçlar tercih etmekteyiz.
            </p>
            <p>
              Maddi manevi içinizin rahat olacağı taşınma işlemlerinizde sigorta
              kapsamından faydalanmanızı öneririz. Büyük bir dikkat ve özveriyle
              çalışan uzman ekibimiz, taşınma işinizi en hızlı şekilde
              gerçekleştirmektedir. Hızlı bir paketleme sürecinin ardından hızlı
              bir sevk süreci ve yine hızlı bir eşya yerleştirme işlemi ile
              vakitten tasarruf yapmanızı sağlamaktayız. Şehir içi taşımacılık
              esnasında konforlu bir deneyim yaşamak için Özfilo Evden Eve
              Nakliyat firmamızı tercih edebilirsiniz.
            </p>
          </div>
        </div>
        <div>
          <h2 className="mx-auto text-3xl lg:text-6xl mt-10 mb-3">
            Asansörlü Evden Eve Nakliyat
          </h2>
          <img
            src={img5}
            className="mx-auto lg:h-[800px]"
            alt="Özfilo - Asansörlü Evden Eve Nakliyat"
          />
          <div className=" flex flex-col lg:text-xl text-gray-600 space-y-10 my-10">
            <p>
              Firmamız, taşınma işlerinizi en sorunsuz şekilde gerçekleştirerek
              kaliteli bir hizmet vermeyi amaçlamaktadır. Yıllardır verdiğimiz
              tüm eşya taşıma hizmetlerimiz müşteri memnuniyeti odaklı olmuştur.
              Müşteri memnuniyeti için kaliteli ve hızlı bir hizmetin ne kadar
              önemli olduğunun farkındayız. Bu hizmetlerden biri de{" "}
              <strong>asansörlü evden eve nakliyat</strong> hizmetidir.
              Çalışmalarımızı yürütürken son teknoloji araç ve ekipmanlar
              kullanmaya özen göstermekteyiz. Kullandığımız asansör sistemimiz
              de son teknoloji ve kaliteli hizmet için tasarlanmış bir araçtır.
            </p>
            <p>
              Asansör sistemi, bir nakliye firmasında kesinlikle bulunması
              gereken bir donanımdır. Eşyalarınız hızlı bir şekilde nakliye
              aracına yüklenebilmesi için olması gereken pratik bir yöntemdir.
              Hızlı ve güvenli hizmet anlayışımız doğrultusunda en gelişmiş
              teknoloji ürünü asansör sistemi kullanmaktayız. İnsan gücünün bir
              noktadan sonra yeterli gelmediği ve işleri yavaşlattığı noktada,
              teknolojik araçların gücünden faydalanmakta ve hizmet kalitemizi
              arttırmaktayız.
            </p>
            <p>
              <strong>Evden eve nakliyat</strong> hizmetimizin kaliteli bir
              şekilde sunulabilmesi için kullandığımız teknolojiler bir hizmet
              seçeneğidir. Eşyalarınızın bina içinde taşınması alan darlığından
              dolayı hasar riskini arttırabilmektedir. Bina içinde indirilen
              eşyaların çarpma, vurma ve düşürülme riski çok daha fazladır. Bu
              noktada asansör sistemimiz devreye girmektedir. Kaliteli bir
              taşınma hizmeti verebilmek adına olası hasar risklerini detaylıca
              planlamaktayız. Bu plan doğrultusunda alınması gereken en doğru
              kararları alarak profesyonel bir hizmet sunmaktayız.
            </p>
            <p>
              Bina çevresinde uygun görülen bir alana kurduğumuz asansör
              sistemimiz, eşyalarınızın katlardan indirilmesi veya çıkarılması
              riskine karşı son teknoloji bir önlemdir. Kurulan asansör sistemi
              ile tüm eşyalarınız büyük bir kolaylıkla dilediğiniz kata
              taşınmaktadır. Gönül rahatlığı ile güvenilir bir şekilde taşınmak
              istiyorsanız firmamızı tercih edebilir ve bu gibi hizmet
              seçeneklerimizden yararlanabilirsiniz.
            </p>
          </div>
        </div>
        <div>
          <h2 className="mx-auto text-3xl lg:text-6xl mt-10 mb-3">
            Asansörlü Evden Eve Nakliyat
          </h2>

          <div className=" flex flex-col lg:text-xl text-gray-600 space-y-10 my-10">
            <p>
              Firmamızın belirlediği{" "}
              <strong>evden eve nakliyat fiyatları</strong>, her alanda olduğu
              gibi yine müşteri odaklıdır. Her cebe uygun hizmet seçeneklerimiz
              bulunmaktadır. Firmamızı tercih etmeniz halinde en kaliteli
              hizmetlerden en uygun fiyatlara faydalanabilirsiniz. Özfilo Evden
              Eve Nakliyat olarak vizyonumuz, ihtiyacı olan herkese uygun
              fiyatlı ve kaliteli hizmet götürebilmektir. Zorlu taşınma
              süreçlerinizde stresinizi en aza indirerek çözüm ortağı olmayı
              tercih etmekteyiz.
            </p>
            <p>
              Şehirlerarası taşınma işlemleriniz için fiyatlandırma politikamız
              mesafe bazında ölçülmektedir. Mesafenin uzunluğuna göre titizlikle
              hazırlanacak olan bir fiyatlandırma politikamız bulunmaktadır.
              Bunun yanı sıra sigorta için de uygun fiyatlandırma seçenekleri
              sunmaktayız. Ödeyeceğiniz ek sigorta ücreti ile içiniz rahat, risk
              durumunda zararınızın karşılanacağından emin bir hizmet
              alabilirsiniz.
            </p>
            <p>
              Prensiplerimiz gereği anlaşmaya uygun zamanında taşıma en dikkat
              ettiğimiz husustur. Taşınma esnasında eşyalarınızın cinsine uygun
              olarak yaratıcı çözümler bulmaktayız. Paketleme işlemi için
              kullanmakta olduğumuz ambalajların kaliteli ve temiz olmasına
              dikkat etmekteyiz. Eşyalarınıza gösterdiğiniz hassasiyeti
              önemseyerek en kaliteli hizmetleri sunmak için maksimum çaba
              harcamaktayız. Üstelik tüm bu hizmetler için uygun fiyatlandırma
              seçenekleri sunmaktayız.
            </p>
            <p>
              <strong>Evden eve nakliyat</strong> ihtiyaçlarınız için Özfilo
              Evden Eve Nakliyat firmamızla 7/24 irtibata geçebilir, detaylı
              hizmet ve fiyatlandırma bilgisi alabilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EvdenEve;
